import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import WaterIntakeForm from "../../components/forms/calculators/water-calculator";

export default function WaterIntake() {
  // Variables
  const [data, setData] = useState(null);
  const [results, setResults] = useState(null);
  const [showResults, setShowResults] = useState(false);

  // Use Effect
  useEffect(() => {
    if (data) {
      // Calculate
      let water = calculate(data);
      // Set Results
      let r = {
        value: water,
      };
      setResults(r);
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [data]);

  // Handlers
  const handleData = (data) => {
    // Set Data
    setData(data);
  };

  // Calculate BMI
  function calculate(data) {
    if (data.isMetric) {
      // METRIC Calculation
      let value;
      let w = data && data.weight1 ? parseInt(data.weight1) : 0;
      let goal = data && data.goal ? parseInt(data.goal) : 0;
      // Calculate
      value = (w * 35 + goal * 750) / 1000;
      return Math.round(value * 10) / 10;
    } else {
      // Imperial Calculation
      let value;
      let w = data && data.weight2 ? parseInt(data.weight2) * 0.453592 : 0;
      let goal = data && data.goal ? data.goal : 0;
      value = ((w * 35 + goal * 750) * 33.814) / 1000;
      // Calculate
      return Math.round(value * 10) / 10;
    }
  }

  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/healthyliving/water.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          height: 400,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <WaterIntakeForm handleData={handleData} />
      <section
        className="section"
        style={{
          marginBottom: 0,
          paddingBottom: 0,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        {showResults ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card border-0 shadow rounded overflow-hidden">
                  <h4>You Need</h4>
                  <h3>
                    <span className="fw-bolder text-danger">
                      {results.value}
                    </span>
                  </h3>
                  <h4>
                    {data && data.isMetric ? "liters" : "ounces"} of Water / day
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <section
        className="section"
        style={{
          marginBottom: 5,
          paddingBottom: 5,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">Water Intake</h3>
                  {/*<h5 className="card-title">What is BMI?</h5>*/}
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Our water calculator will help you easily estimate how
                      much water you need per day including how much of it you
                      will need to drink in the form of fluids (pure water or
                      beverages). The outputs of our water intake calculator are
                      in liters, milliliters, cups (equivalent to a standard
                      glass), and ounces of water. Of course, these are
                      estimates based on population averages so consulting your
                      physician or nutritionist is always recommended before
                      making changes to your water consumption or exercise
                      routine.
                    </p>
                    <p className="text-muted">
                      Calculating your recommended total daily water intake and
                      thus optimal hydration requires the estimation of your
                      total daily energy expenditure (TDEE) which measures how
                      much energy (in kcal, kilocalories) you expend during a
                      typical day. Unlike other tools that estimate daily water
                      intake by weight (in kg/litre), this tool employs a rule
                      for water needs in relation to one's energy requirements
                      expressed in ml/kcal. This method is superior since energy
                      requirements are strongly evidence-based in each age and
                      gender group and take into account body size, body weight,
                      and activity level. These are crucial determinants of the
                      energy needs that must be met by dietary intake.
                    </p>
                    <p className="text-muted">
                      Those same determinants are also applicable to water
                      utilization and balance which provides an argument for
                      pegging water/fluid intake recommendations to the better
                      studied energy recommendations. The extent to which water
                      intake requirements are determined by energy needs is
                      understudied but in the clinical setting it has long been
                      practice to supply 1 ml per kcal administered by tube to
                      patients unable to take in food or fluids [1]. Since using
                      TDEE avoids the compromise inherent in other Adequate
                      Water Intake estimations, this daily water intake
                      calculator bases its output on the link between estimated
                      energy needs and fluids intake needs.
                    </p>
                  </ul>

                  <h5 className="card-title">
                    Sources of water in the human body:
                  </h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      Water input in the human body comes from three main
                      sources: water and other beverages, food, and as a
                      side-effect of metabolic processes. Since water and
                      beverages are only a part of the input, our calculator
                      will output both your total water intake recommendation as
                      well as how much of it you need to get through drinking
                      fluids.
                    </p>
                    <p>
                      You should be careful to not confuse how much water you
                      need with how much water you need to drink. The latter is
                      usually only around 4/5 of the total, thus you actually
                      need to drink slightly less water than your total daily
                      needs. Say you need a total of 70 ounces per day. This
                      would mean that you need to drink just 56 oz of water a
                      day while the rest will enter your body through food and
                      metabolic processes. That equals about 7 cups a day which
                      is roughly equivalent to 7 standard water glasses a day
                    </p>
                  </ul>

                  <h5 className="card-title">
                    General water drinking recommendations:
                  </h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      It should be noted that, in general, our bodies are fairly
                      good at estimating how much water one needs on a daily
                      basis and this happens via the mechanism of thirst. If you
                      are thirsty, you should certainly drink water no matter
                      what any kind of calculation or chart is telling you about
                      how much water per day you need to drink.
                    </p>
                    <div className="table-responsive shadow rounded mt-4">
                      <table className="table table-center invoice-tb mb-0">
                        <thead>
                          <tr>
                            <th
                              rowSpan="2"
                              style={{ verticalAlign: "middle" }}
                              className="cinfoHd"
                            >
                              Age group
                            </th>
                            <th
                              colSpan="2"
                              style={{ textAlign: "center" }}
                              className="cinfoHdL"
                            >
                              ESFA
                            </th>
                            <th
                              colSpan="2"
                              style={{ textAlign: "center" }}
                              className="cinfoHdL"
                            >
                              IOM
                            </th>
                          </tr>
                          <tr>
                            <th className="cinfoHd">Total water intake</th>
                            <th className="cinfoHd">Fluid intake</th>
                            <th className="cinfoHd">Total water intake</th>
                            <th className="cinfoHd">Fluid intake</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>0-6 mo.</td>
                            <td>0.68 (milk)</td>
                            <td>0.68 (milk)</td>
                            <td>0.70</td>
                            <td>0.70</td>
                          </tr>
                          <tr>
                            <td>6-12 mo.</td>
                            <td>0.80 - 1.00</td>
                            <td>0.64 - 0.80</td>
                            <td>0.80</td>
                            <td>0.80</td>
                          </tr>
                          <tr>
                            <td>1-2 years</td>
                            <td>1.10 - 1.20</td>
                            <td>0.88 - 0.90</td>
                            <td>N/A</td>
                            <td>N/A</td>
                          </tr>
                          <tr>
                            <td>2-3 years</td>
                            <td>1.30</td>
                            <td>1.00</td>
                            <td>N/A</td>
                            <td>N/A</td>
                          </tr>
                          <tr>
                            <td>1-3 years</td>
                            <td>N/A</td>
                            <td>N/A</td>
                            <td>1.30</td>
                            <td>0.90</td>
                          </tr>
                          <tr>
                            <td>4-8 years</td>
                            <td>1.60</td>
                            <td>1.20</td>
                            <td>1.70</td>
                            <td>1.20</td>
                          </tr>
                          <tr>
                            <td>9-13 y. Boys</td>
                            <td>2.10</td>
                            <td>1.60</td>
                            <td>2.40</td>
                            <td>1.80</td>
                          </tr>
                          <tr>
                            <td>9-13 y. Girls</td>
                            <td>1.90</td>
                            <td>1.50</td>
                            <td>2.10</td>
                            <td>1.60</td>
                          </tr>
                          <tr>
                            <td>Boys 14+ &amp; Adult Men</td>
                            <td>2.50</td>
                            <td>2.00</td>
                            <td>3.30</td>
                            <td>2.60</td>
                          </tr>
                          <tr>
                            <td>Girls 14+ &amp; Adult Women</td>
                            <td>2.00</td>
                            <td>1.60</td>
                            <td>2.30</td>
                            <td>1.80</td>
                          </tr>
                          <tr>
                            <td>Pregnant Women</td>
                            <td>2.30</td>
                            <td>1.84</td>
                            <td>2.60</td>
                            <td>1.90</td>
                          </tr>
                          <tr>
                            <td>Lactating Women</td>
                            <td>2.60</td>
                            <td>2.10</td>
                            <td>3.40</td>
                            <td>2.80</td>
                          </tr>
                          <tr>
                            <td>Elderly</td>
                            <td>As adults</td>
                            <td>As adults</td>
                            <td>As adults</td>
                            <td>As adults</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
