import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";

import Header from "../../components/header/header";
import AboutUsMini from "../../components/aboutus-mini/aboutus-mini";
import { MdOutlineLocalPrintshop } from "react-icons/md";

export default function Terms() {
  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/bg/terms.avif)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
          overflow: "scroll",
          // width: 500,
          height: 600,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="section-title text-center">
              <nav aria-label="breadcrumb" className="d-inline-block mt-3 mb-3">
                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                  <li className="breadcrumb-item">
                    <Link to="/">ZipLaneRx</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Terms
                  </li>
                </ul>
              </nav>
              <AboutUsMini />
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">Terms & Conditions:</h3>
                  <p className="text-muted">
                    Welcome to ZipLaneRx, a digital healthcare marketplace
                    providing NHS & private services, prescription and repeat
                    prescription orders, and wellness products (“Platform”).
                    Before using our Platform, please carefully read these Terms
                    and Conditions (“Terms”) as they govern your access to and
                    use of our services. By using our Platform, you agree to be
                    bound by these Terms.
                  </p>

                  <h5 className="card-title">1. Use of Platform</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      1.1. Eligibility: You must be at least 18 years old and
                      capable of entering into a legally binding agreement to
                      use our Platform. By using our Platform, you represent and
                      warrant that you meet these eligibility requirements.
                    </p>
                    <p className="text-muted">
                      1.2. Account Registration: You may need to create an
                      account to access certain features of our Platform. You
                      are responsible for maintaining the confidentiality of
                      your account information and for all activities that occur
                      under your account.
                    </p>
                    <p className="text-muted">
                      1.3. Prohibited Activities: You agree not to engage in any
                      prohibited activities while using our Platform, including
                      but not limited to: violating any laws or regulations,
                      infringing upon the rights of others, or transmitting
                      harmful or malicious code.
                    </p>
                  </ul>

                  <h5 className="card-title">2. Services Offered</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      2.1. NHS & Private Services: Our Platform connects users
                      with healthcare providers offering both NHS and private
                      services. We do not directly provide medical services and
                      are not liable for the quality or outcomes of such
                      services.
                    </p>
                    <p className="text-muted">
                      2.2. Prescription and Repeat Prescription Orders: Users
                      may request prescription and repeat prescription orders
                      through our Platform. We do not verify the accuracy of
                      prescriptions provided by users and are not liable for any
                      consequences arising from incorrect or misrepresented
                      prescriptions.
                    </p>
                    <p className="text-muted">
                      2.3. Wellness Products: Users may purchase wellness
                      products through our Platform. We do not manufacture these
                      products and are not liable for any defects or
                      inaccuracies in product descriptions.
                    </p>
                  </ul>

                  <h5 className="card-title">3. No Show Protection</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      3.1. No Show Policy: In the event that a user fails to
                      attend a scheduled appointment with a healthcare provider,
                      the user will be subject to a no show fee, with a maximum
                      value to the amount of the service.
                    </p>
                  </ul>

                  <h5 className="card-title">4. Annual Subscription</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      4.1. Annual Subscriptions: Practitioners and merchant
                      annual subscription will automatically renew unless
                      cancelled. To cancel an annual subscription, you must do
                      so before end end of the current term. Cancellation will
                      take effect at the end of the current subscription term.
                    </p>
                  </ul>

                  <h5 className="card-title">
                    5. Service Appointment Cancellation
                  </h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      5.1. Service Appointment cancellation: Service
                      cancellations can be made unto to 24 hours before the
                      scheduled service due date and time. Cancellations made
                      less than 24 hours before the service due date and time
                      will not be eligible for a refund.
                    </p>
                  </ul>

                  <h5 className="card-title">6. Product Order Cancellations</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      6.1. Product order cancellations Product order placed
                      online can be cancelled within 1 hour of the order time.
                      Cancellations made after this 1-hour window will not be
                      accepted.
                    </p>
                  </ul>

                  <h5 className="card-title">7. Limitation of Liability</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      7.1. No Liability for Products or Services: We do not
                      endorse or guarantee the accuracy, completeness, or
                      reliability of any products or services offered through
                      our Platform. We are not liable for any direct, indirect,
                      incidental, or consequential damages arising from the use
                      or inability to use our Platform or the products and
                      services offered through it.
                    </p>
                  </ul>

                  <h5 className="card-title">8. Indemnification</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      8.1. You agree to indemnify and hold harmless our company,
                      its affiliates, officers, directors, employees, and agents
                      from any claims, damages, liabilities, costs, or expenses
                      (including attorney fees) arising out of or in connection
                      with your use of our Platform or any violation of these
                      Terms.
                    </p>
                  </ul>

                  <h5 className="card-title">9. Governing Law</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      9.1. These Terms shall be governed by and construed in
                      accordance with the laws of England and Wales. Any dispute
                      arising out of or in connection with these Terms shall be
                      subject to the exclusive jurisdiction of the courts of
                      England and Wales.
                    </p>
                  </ul>

                  <h5 className="card-title">10. Changes to Terms</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      10.1. We reserve the right to modify or update these Terms
                      at any time. Any changes will be effective immediately
                      upon posting on our Platform. Your continued use of our
                      Platform after any such changes constitutes your
                      acceptance of the revised Terms.
                    </p>
                  </ul>

                  <h5 className="card-title">11. Contact Us</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      11.1. If you have any questions or concerns about these
                      Terms, please contact us at contact@ziplanerx.com
                    </p>
                  </ul>

                  <h5 className="card-title">ZipLaneRx</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      By using our Platform, you acknowledge that you have read,
                      understood, and agree to be bound by these Terms and our
                      Privacy Policy.
                    </p>
                    <p className="text-muted">Last Updated: March 10, 2024</p>
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
