import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Grid, Switch } from "@mui/material";

const BmiCalculatorForm = ({ handleData }) => {
  // Variables
  let [activeIndex, setActiveIndex] = useState(1);
  // NHS Toggle
  const [isMetric, setIsMetric] = useState({
    state: true,
  });
  const [height1, setHeight1] = useState(null);
  const [height2, setHeight2] = useState(null);
  const [height3, setHeight3] = useState(null);
  const [weight1, setWeight1] = useState(null);
  const [weight2, setWeight2] = useState(null);

  // Effects
  // Handlers
  const handleSystemChange = (name) => (event) => {
    setIsMetric({ ...isMetric, [name]: event.target.checked });
    handleData(null);
  };

  const handleCalculate = () => {
    let values = {
      height1,
      height2,
      height3,
      weight1,
      weight2,
      isMetric: isMetric.state,
    };
    handleData(values);
  };

  // Return
  return (
    <section className="section" style={{ marginBottom: 0, paddingBottom: 0 }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="card border-0 shadow rounded overflow-hidden">
              <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-0 shadow overflow-hidden bg-light mb-0">
                <li className="nav-item">
                  <Link
                    className={`${
                      activeIndex === 1 ? "active" : ""
                    } nav-link rounded-0`}
                    onClick={() => setActiveIndex(1)}
                    to="#"
                  >
                    <div className="text-center pt-1 pb-1">
                      <h5 className="fw-medium mb-0">BMI Calculator</h5>
                    </div>
                  </Link>
                </li>
              </ul>

              <div className="tab-content p-4">
                <div className="tab-pane fade show active">
                  <form>
                    <div className="row">
                      <div
                        className="col-lg-4"
                        style={{ alignContent: "center" }}
                      >
                        <div className="mb-3">
                          <label className="form-label">
                            Units <span className="text-danger"></span>
                          </label>
                          <Grid
                            className="col-md"
                            component="label"
                            container
                            alignItems="center"
                            spacing={1}
                          >
                            <Grid item>Imperial</Grid>
                            <Grid item>
                              <Switch
                                checked={isMetric.state}
                                onChange={handleSystemChange("state")}
                                value="state"
                              />
                            </Grid>
                            <Grid item>Metric</Grid>
                          </Grid>
                        </div>
                      </div>
                      {isMetric.state
                        ? [
                            // Height
                            <div className="col-lg-4">
                              <div className="mb-3">
                                <label className="form-label">
                                  Height (cm){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="height1"
                                  id="height1"
                                  type="text"
                                  className="form-control"
                                  placeholder="Height (cm)"
                                  onChange={(e) => setHeight1(e.target.value)}
                                />
                              </div>
                            </div>,
                            // Weight
                            <div className="col-lg-4">
                              <div className="mb-3">
                                <label className="form-label">
                                  Weight (kg){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="Weight"
                                  id="weight"
                                  type="text"
                                  className="form-control"
                                  placeholder="Weight (kg)"
                                  onChange={(e) => setWeight1(e.target.value)}
                                />
                              </div>
                            </div>,
                          ]
                        : [
                            // Height Ft
                            <div className="col-lg-4">
                              <div className="mb-3">
                                <label className="form-label">
                                  Height (ft){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="height2"
                                  id="height2"
                                  type="text"
                                  className="form-control"
                                  placeholder="Height (ft)"
                                  onChange={(e) => setHeight2(e.target.value)}
                                />
                              </div>
                            </div>,
                            // Height In
                            <div className="col-lg-4">
                              <div className="mb-3">
                                <label className="form-label">
                                  Height (in){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="height3"
                                  id="height2"
                                  type="text"
                                  className="form-control"
                                  placeholder="Height (in)"
                                  onChange={(e) => setHeight3(e.target.value)}
                                />
                              </div>
                            </div>,
                            // Weight lbs
                            <div className="col-lg-12">
                              <div className="mb-3">
                                <label className="form-label">
                                  Weight (lbs){" "}
                                  <span className="text-danger">*</span>
                                </label>
                                <input
                                  name="Weight2"
                                  id="weight2"
                                  type="text"
                                  className="form-control"
                                  placeholder="Weight (lbs)"
                                  onChange={(e) => setWeight2(e.target.value)}
                                />
                              </div>
                            </div>,
                          ]}
                      <div className="col-lg-12">
                        <div className="d-grid">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleCalculate}
                          >
                            Calculate BMI
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BmiCalculatorForm;
