import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import BmiCalculatorForm from "../../components/forms/calculators/bmi-calculator";
import { bmiRange } from "../../data/healthy-living";

export default function Bmi() {
  // Variables
  const [data, setData] = useState(null);
  const [results, setResults] = useState(null);
  const [showResults, setShowResults] = useState(false);

  // Use Effect
  useEffect(() => {
    if (data) {
      // Calculate
      let bmi = calculateBMI(data);
      // Set Results
      let r = {
        bmi: bmi,
        classification: classifyBmi(bmi),
      };
      setResults(r);
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [data]);

  // Handlers
  const handleBmiData = (data) => {
    // Set Data
    setData(data);
  };

  // Calculate BMI
  function calculateBMI(data) {
    if (data.isMetric) {
      // METRIC Calculation
      let h = data && data.height1 ? data.height1 / 100 : 0;
      let w = data && data.weight1 ? data.weight1 : 0;
      let bmi = w / (h * h);
      return Math.round(bmi * 10) / 10;
    } else {
      // Imperial Calculation
      let h1 = data && data.height2 ? data.height2 * 12 : 0;
      let h2 = data && data.height3 ? data.height3 : 0;
      let h = parseInt(h1) + parseInt(h2);
      let w = data && data.weight2 ? data.weight2 * 703 : 0;
      let bmi = w / (h * h);
      return Math.round(bmi * 10) / 10;
    }
  }

  function classifyBmi(bmi) {
    if (!bmi) {
      return "";
    }

    if (bmi < 16) {
      return "Severe Thinness";
    } else if (bmi >= 16 && bmi < 17) {
      return "Moderate Thinness";
    } else if (bmi >= 17 && bmi < 18.5) {
      return "Mild Thinness";
    } else if (bmi >= 18.5 && bmi < 25) {
      return "Normal";
    } else if (bmi >= 25 && bmi < 30) {
      return "Overweight";
    } else if (bmi >= 30 && bmi < 35) {
      return "Obese Class 1";
    } else if (bmi >= 35 && bmi < 40) {
      return "Obese Class 2";
    } else if (bmi >= 40) {
      return "Obese Class 3";
    }
  }

  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/heart.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          height: 400,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <BmiCalculatorForm handleData={handleBmiData} />
      <section
        className="section"
        style={{
          marginBottom: 0,
          paddingBottom: 0,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        {showResults ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card border-0 shadow rounded overflow-hidden">
                  <h3>
                    BMI = <span className="text-danger">{results.bmi}</span>
                  </h3>
                  <h5>({results.classification})</h5>
                  <p>
                    Healthy BMI range: 18.5 kg/m<sup>2</sup> - 25 kg/m
                    <sup>2</sup>
                  </p>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <section
        className="section"
        style={{
          marginBottom: 5,
          paddingBottom: 5,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">BMI</h3>
                  <h5 className="card-title">What is BMI?</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      The Body Mass Index (BMI) Calculator can be used to
                      calculate BMI value and corresponding weight status while
                      taking age into consideration. Use the "Metric Units" tab
                      for the International System of Units or the "Other Units"
                      tab to convert units into either US or metric units. Note
                      that the calculator also computes the Ponderal Index in
                      addition to BMI, both of which are discussed below in
                      detail.
                    </p>
                  </ul>

                  <h5 className="card-title">BMI introduction:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      BMI is a measurement of a person's leanness or corpulence
                      based on their height and weight, and is intended to
                      quantify tissue mass. It is widely used as a general
                      indicator of whether a person has a healthy body weight
                      for their height. Specifically, the value obtained from
                      the calculation of BMI is used to categorize whether a
                      person is underweight, normal weight, overweight, or obese
                      depending on what range the value falls between. These
                      ranges of BMI vary based on factors such as region and
                      age, and are sometimes further divided into subcategories
                      such as severely underweight or very severely obese. Being
                      overweight or underweight can have significant health
                      effects, so while BMI is an imperfect measure of healthy
                      body weight, it is a useful indicator of whether any
                      additional testing or action is required. Refer to the
                      table below to see the different categories based on BMI
                      that are used by the calculator
                    </p>
                  </ul>

                  <h5 className="card-title">BMI Table for Adults:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      This is the World Health Organization's (WHO) recommended
                      body weight based on BMI values for adults. It is used for
                      both men and women, age 20 or older.
                    </p>
                    <div className="table-responsive shadow rounded mt-4">
                      <table className="table table-center invoice-tb mb-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "60px" }}
                            >
                              Classification
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              BMI Range - kg/m<sup>2</sup>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {bmiRange.map((range) => {
                            return (
                              <tr>
                                <th scope="row" className="text-start p-3">
                                  {range.classification}
                                </th>
                                <td className="text-start p-3">
                                  {range.range}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </ul>

                  <h5 className="card-title">BMI Chart For Adults</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      This is a graph of BMI categories based on the World
                      Health Organization data. The dashed lines represent
                      subdivisions within a major categorization.
                    </p>
                    <img
                      src={"assets/images/img_1.png"}
                      className="img-fluid rounded shadow"
                      alt=""
                    />
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
