// two-field-search
import React from "react";
import { Link } from "react-router-dom";
import { RiHome2Line, RiPrinterLine } from "react-icons/ri";
import moment from "moment";

const ProductInvoice = ({ data }) => {
  // TODO: REMOVE HARDCODING
  // Functions
  const date = new Date();
  return (
    <div className="col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-lg-10">
            <div className="card border-0 rounded shadow px-4 py-5">
              <div className="row-mb-0 text-start">
                <div className="col">
                  <img src={"assets/images/logo-icon.png"} height="75" alt="" />
                  <h6 className="mt-0 pt-0 text-primary">ZipLaneRx</h6>
                </div>
              </div>
              <div className="row mb-4">
                <div className="col-lg-8 col-md-6 text-start">
                  <h6 className="mt-1 pt-2">Pick Up Address</h6>
                  <ul className="list-unstyled">
                    <li className="d-flex ms-0">
                      <small className="mb-0 text-muted">
                        Mango Pharmacy
                        {/*{data?.addressLine1}{" "}*/}
                        {/*{data?.addressLine2 ? "," + data.addressLine2 : ""}*/}
                      </small>
                    </li>
                    <li className="d-flex ms-0">
                      <small className="mb-0 text-muted">
                        98 High Street,
                        {/*{data?.addressLine1}{" "}*/}
                        {/*{data?.addressLine2 ? "," + data.addressLine2 : ""}*/}
                      </small>
                    </li>
                    <li className="d-flex ms-0">
                      <small className="mb-0 text-muted">
                        Edgware
                        {/*{data?.cityName}*/}
                      </small>
                    </li>
                    <li className="d-flex ms-0">
                      <small className="mb-0 text-muted">
                        United Kingdom, HA8 7HF
                        {/*{data?.countryName}, {data?.postalCode}*/}
                      </small>
                    </li>
                  </ul>
                </div>

                <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                  <ul className="list-unstyled">
                    <li className="d-flex ms-0">
                      <small className="mb-0 text-muted">Invoice no. : </small>
                      <small className="mb-0 text-dark">
                        &nbsp;&nbsp;{data?.productOrderId || 1837}
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Email : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;
                        <Link
                          to="mailto:contact@example.com"
                          className="text-dark"
                        >
                          {data?.emailAddress || "contact@ziplanerx.com"}
                        </Link>
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Phone : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;
                        <Link to="tel:+152534-468-854" className="text-dark">
                          {data?.phoneNumber || "6095589535"}
                        </Link>
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Website : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;
                        <Link to="" className="text-dark">
                          www.ziplanerx.com
                        </Link>
                      </small>
                    </li>
                    <li className="d-flex ms-0 mt-2">
                      <small className="mb-0 text-muted">Patient Name : </small>
                      <small className="mb-0">
                        &nbsp;&nbsp;
                        {`${data?.firstName ? data.firstName : "John"} ${
                          data?.lastName ? data.lastName : "Doe"
                        }`}
                      </small>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="pt-4 border-top">
                <div className="row">
                  <div className="col-lg-8 col-md-6">
                    <h5 className="text-muted fw-bold">
                      Invoice{" "}
                      <span className="badge rounded-pill bg-soft-success fw-normal ms-2">
                        Paid
                      </span>
                    </h5>
                    <h6>{data?.serviceName}</h6>
                  </div>

                  <div className="col-lg-4 col-md-6 mt-4 mt-sm-0 pt-2 pt-sm-0">
                    <ul className="list-unstyled">
                      <li className="d-flex mt-2 ms-0">
                        <small className="mb-0 text-muted">Order Dt. : </small>
                        <small className="mb-0 text-dark">
                          &nbsp;&nbsp;{moment(date).format("MMM Do YYYY")}
                        </small>
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="invoice-table pb-4">
                  <div className="table-responsive shadow rounded mt-4">
                    <table className="table table-center invoice-tb mb-0">
                      <thead>
                        <tr>
                          <th
                            className="border-bottom p-3"
                            style={{ minWidth: "150px" }}
                          >
                            Product
                          </th>
                          <th
                            className="border-bottom text-center p-3"
                            style={{ minWidth: "150px" }}
                          >
                            Price
                          </th>
                          <th
                            className="border-bottom text-center p-3"
                            style={{ minWidth: "150px" }}
                          >
                            Qty
                          </th>
                          <th
                            className="border-bottom text-center p-3"
                            style={{ minWidth: "150px" }}
                          >
                            VAT
                          </th>
                          <th
                            className="border-bottom text-end p-3"
                            style={{ minWidth: "150px" }}
                          >
                            Total
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.products?.map((item, index) => {
                          if (item.productCount > 0) {
                            return (
                              <tr key={index}>
                                <td className="p-3">
                                  <div className="d-flex align-items-center">
                                    {/*<img*/}
                                    {/*  src={`data:image/gif;base64,${item.image}`}*/}
                                    {/*  className="img-fluid avatar avatar-small rounded shadow"*/}
                                    {/*  style={{ width: 50, height: 50 }}*/}
                                    {/*  alt=""*/}
                                    {/*/>*/}
                                    <h6 className="mb-0 ms-3">
                                      <Link
                                        to={`/shop/products/${item.productId}`}
                                      >
                                        {item.productName}
                                      </Link>
                                    </h6>
                                  </div>
                                </td>
                                <td className="text-center p-3">
                                  {item.productCost
                                    ? `£ ${item.productCost}`
                                    : 0}
                                </td>
                                <td className="text-center shop-list p-3">
                                  {item.productCount}
                                </td>
                                <td className="text-center shop-list p-3">
                                  {item.productVat}
                                </td>
                                <td className="text-end font-weight-bold p-3">
                                  £ {item.productSubTotalAmount}
                                </td>
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })}
                      </tbody>
                    </table>
                  </div>

                  <div className="row">
                    <div className="col-lg-4 col-md-5 ms-auto">
                      <ul className="list-unstyled h6 fw-normal mt-4 mb-0 ms-md-5 ms-lg-4">
                        <li className="d-flex justify-content-between pe-3 ms-0">
                          Delivery Fee : <span>£ {data.deliveryFee}</span>
                        </li>
                        <li className="d-flex justify-content-between pe-3 ms-0">
                          Total : <span>£ {data.orderTotal}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="border-top pt-4">
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="text-sm-start text-muted text-center">
                        <small className="mb-0">
                          Customer Service :{" "}
                          <Link
                            to="tel:+152534-468-854"
                            className="text-warning"
                          >
                            contact@ziplanerx.com
                          </Link>
                        </small>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="text-sm-end text-muted text-center">
                        <small className="mb-0">
                          <Link to="/aboutus/terms" className="text-primary">
                            Terms & Conditions
                          </Link>
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-end mt-4 pt-2">
              <Link
                to="/home"
                onClick={() => window.open("/home", "_self")}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiHome2Line /> Home
              </Link>
              <Link
                to="#"
                onClick={() => window.print()}
                className="btn btn-soft-primary d-print-none"
                style={{ margin: 5 }}
              >
                <RiPrinterLine /> Print
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductInvoice;
