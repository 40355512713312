import auth0 from "auth0-js";
import { jwtDecode } from "jwt-decode";

export default class AuthService {
  auth0 = new auth0.WebAuth({
    domain: `${process.env.REACT_APP_auth0_domain}`,
    clientID: `${process.env.REACT_APP_auth0_clientId}`,
    redirectUri: `${process.env.REACT_APP_frontoffice_base}/patient/authenticate`,
    audience: `${process.env.REACT_APP_AUTH_AUD}`,
    responseType: "token id_token",
    scope: "openid profile email",
  });

  login(payload) {
    return new Promise((resolve, reject) => {
      this.auth0.login(payload, function (error, response) {
        if (error) {
          reject(error);
        } else if (response) {
          resolve(response);
        }
      });
    });
  }

  signup(payload) {
    return new Promise((resolve, reject) => {
      this.auth0.signup(payload, function (error, response) {
        if (error) {
          reject(error);
        } else if (response) {
          resolve(response);
        }
      });
    });
  }

  handleAuthentication(hash) {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash({ hash: hash }, (err, authResult) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          this.setSession(authResult);

          resolve(authResult);
        } else if (err) {
          console.error(err);
          reject(err);
        }
      });
    });
  }

  setSession(authResult) {
    const expiresAt = JSON.stringify(
      authResult.expiresIn * 1000 + new Date().getTime(),
    );
    localStorage.setItem("_accessToken", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("expires_at", expiresAt);
    sessionStorage.setItem("_accessToken", authResult.accessToken);
    sessionStorage.setItem("id_token", authResult.idToken);
    sessionStorage.setItem("expires_at", expiresAt);
  }

  logout() {
    localStorage.clear();
    sessionStorage.clear();
    this.auth0.logout({
      returnTo: window.location.origin,
    });
  }

  isAuthenticated() {
    const expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  }

  getProfile() {
    return new Promise((resolve, reject) => {
      const id_token = localStorage.getItem("id_token");
      if (!id_token) {
        reject(new Error("No access token found"));
        return;
      }
      let profile = jwtDecode(id_token);
      // console.log(profile);
      if (profile) {
        localStorage.setItem("_authUserProfile", JSON.stringify(profile));
        sessionStorage.setItem("_authUserProfile", JSON.stringify(profile));
        resolve(profile);
      } else {
        reject();
      }
    });
  }
}
