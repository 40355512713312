import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import {
  meatDairyProteinList,
  partialProteinList,
  proteinAmountFood,
  proteinReqPregnancy,
  rdaProtein,
  veganProteinList,
} from "../../data/healthy-living";
import { FiArrowRightCircle } from "react-icons/fi";
import ProteinCalculatorForm from "../../components/forms/calculators/protein-calculator";

export default function Protein() {
  // Variables
  const [data, setData] = useState(null);
  const [results, setResults] = useState(null);
  const [showResults, setShowResults] = useState(false);

  // Use Effect
  useEffect(() => {
    if (data) {
      // Calculate
      let protein = calculate(data);
      // Set Results
      let r = {
        value: protein,
      };
      setResults(r);
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [data]);

  // Handlers
  const handleData = (data) => {
    // Set Data
    setData(data);
  };

  // Calculate BMI
  function calculate(data) {
    if (data.isMetric) {
      // METRIC Calculation
      let value;
      let w = data && data.weight1 ? parseInt(data.weight1) : 0;
      let goal = data && data.goal ? data.goal : "Build Muscle";
      // Calculate
      if (goal === "Build Muscle") {
        value = w * 1.5;
      }
      if (goal === "Improve Endurance") {
        value = w * 1.2;
      }
      if (goal === "Balanced Nutrition") {
        value = w;
      }
      return value;
    } else {
      // Imperial Calculation
      let value;
      let w = data && data.weight2 ? parseInt(data.weight2) : 0;
      let goal = data && data.goal ? data.goal : "Build Muscle";
      // Calculate
      if (goal === "Build Muscle") {
        value = w / (220.462 / 150);
      }
      if (goal === "Improve Endurance") {
        value = w / (220.462 / 120);
      }
      if (goal === "Balanced Nutrition") {
        value = w / (220.462 / 100);
      }
      return value;
    }
  }

  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/healthyliving/protein.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          height: 400,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <ProteinCalculatorForm handleData={handleData} />
      <section
        className="section"
        style={{
          marginBottom: 0,
          paddingBottom: 0,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        {showResults ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card border-0 shadow rounded overflow-hidden">
                  <h4>You Need</h4>
                  <h3>
                    <span className="fw-bolder text-danger">
                      {results.value}
                    </span>
                  </h3>
                  <h4>grams of Protein / day</h4>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <section
        className="section"
        style={{
          marginBottom: 5,
          paddingBottom: 5,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">Proteins</h3>
                  <h5 className="card-title">What are proteins?</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Proteins are one of three primary macronutrients that
                      provide energy to the human body, along with fats and
                      carbohydrates. Proteins are also responsible for a large
                      portion of the work that is done in cells; they are
                      necessary for proper structure and function of tissues and
                      organs, and also act to regulate them. They are comprised
                      of a number of amino acids that are essential to proper
                      body function, and serve as the building blocks of body
                      tissue.
                    </p>
                    <p className="text-muted">
                      There are 20 different amino acids in total, and the
                      sequence of amino acids determines a protein's structure
                      and function. While some amino acids can be synthesized in
                      the body, there are 9 amino acids that humans can only
                      obtain from dietary sources (insufficient amounts of which
                      may sometimes result in death), termed essential amino
                      acids. Foods that provide all of the essential amino acids
                      are called complete protein sources, and include both
                      animal (meat, dairy, eggs, fish) as well as plant-based
                      sources (soy, quinoa, buckwheat).
                    </p>
                    <p className="text-muted">
                      Proteins can be categorized based on the function they
                      provide to the body. Below is a list of some types of
                      proteins:
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Antibody</span>: proteins that
                        protect the body from foreign particles, such as viruses
                        and bacteria, by binding to them
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Enzyme</span>: proteins that
                        help form new molecules as well as perform the many
                        chemical reactions that occur throughout the body
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Messenger</span>: proteins
                        that transmit signals throughout the body to maintain
                        body processes
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Structural component</span>:
                        proteins that act as building blocks for cells that
                        ultimately allow the body to move
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Transport/storage</span>:
                        proteins that move molecules throughout the body
                      </li>
                    </p>
                    <p className="text-muted">
                      As can be seen, proteins have many important roles
                      throughout the body, and as such, it is important to
                      provide sufficient nutrition to the body to maintain
                      healthy protein levels.
                    </p>
                  </ul>

                  <h5 className="card-title">How much protein do I need?</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      The amount of protein that the human body requires daily
                      is dependent on many conditions, including overall energy
                      intake, growth of the individual, and physical activity
                      level. It is often estimated based on body weight, as a
                      percentage of total caloric intake (10-35%), or based on
                      age alone. 0.8g/kg of body weight is a commonly cited
                      recommended dietary allowance (RDA). This value is the
                      minimum recommended value to maintain basic nutritional
                      requirements, but consuming more protein, up to a certain
                      point, maybe beneficial, depending on the sources of the
                      protein.
                    </p>

                    <p>
                      The recommended range of protein intake is between 0.8
                      g/kg and 1.8 g/kg of body weight, dependent on the many
                      factors listed above. People who are highly active, or who
                      wish to build more muscle should generally consume more
                      protein. Some sources suggest consuming between 1.8 to 2
                      g/kg for those who are highly active. The amount of
                      protein a person should consume, to date, is not an exact
                      science, and each individual should consult a specialist,
                      be it a dietitian, doctor, or personal trainer, to help
                      determine their individual needs.
                    </p>
                  </ul>

                  <h4 className="card-title">
                    Recommended dietary allowance (RDA) of protein, based on
                    age:
                  </h4>
                  <ul className="list-unstyled text-muted">
                    <div className="table-responsive shadow rounded mt-4">
                      <table className="table table-center invoice-tb mb-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "60px" }}
                            >
                              Age Group
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              Protein Needed (grams/day)
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {rdaProtein.map((r) => {
                            return (
                              <tr>
                                <th scope="row" className="text-start p-3">
                                  {r.group}
                                </th>
                                <td className="text-start p-3">{r.value}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </ul>

                  <h4 className="card-title">
                    Extra Protein Requirements for Pregnancy and Lactation
                  </h4>
                  <ul className="list-unstyled text-muted">
                    <div className="table-responsive shadow rounded mt-4">
                      <table className="table table-center invoice-tb mb-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "60px" }}
                            >
                              Classification
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              Safe Intake (grams / day)
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              Additional Energy Requirement (kJ/day)
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              Protein : energy ratio
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {proteinReqPregnancy.map((r) => {
                            return (
                              <tr>
                                <th scope="row" className="text-start p-3">
                                  {r.group}
                                </th>
                                <td className="text-start p-3">{r.safe}</td>
                                <td className="text-start p-3">
                                  {r.additional}
                                </td>
                                <td className="text-start p-3">{r.ratio}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </ul>

                  <h5 className="card-title">Foods high in protein</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      There are many different combinations of food that a
                      person can eat to meet their protein intake requirements.
                      For many people, a large portion of protein intake comes
                      from meat and dairy, though it is possible to get enough
                      protein while meeting certain dietary restrictions you
                      might have. Generally, it is easier to meet your RDA of
                      protein by consuming meat and dairy, but an excess of
                      either can have a negative health impact. There are plenty
                      of plant-based protein options, but they generally contain
                      less protein in a given serving. Ideally, a person should
                      consume a mixture of meat, dairy, and plant-based foods in
                      order to meet their RDA and have a balanced diet replete
                      with nutrients.
                    </p>
                    <p className="text-muted">
                      If possible, consuming a variety of complete proteins is
                      recommended. A complete protein is a protein that contains
                      a good amount of each of the nine essential amino acids
                      required in the human diet. Examples of complete protein
                      foods or meals include:
                    </p>
                    <p className="text-muted">
                      <span className="fw-bold">Meat / Dairy Examples:</span>
                      {meatDairyProteinList.map((food) => {
                        return (
                          <li>
                            <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                            {food}
                          </li>
                        );
                      })}
                    </p>
                    <p className="text-muted">
                      <span className="fw-bold">
                        Vegan / Plant Based Examples:
                      </span>
                      {veganProteinList.map((food) => {
                        return (
                          <li>
                            <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                            {food}
                          </li>
                        );
                      })}
                    </p>
                    <p className="text-muted">
                      Generally, meat, poultry, fish, eggs, and dairy products
                      are complete protein sources. Nuts and seeds, legumes,
                      grains, and vegetables, among other things, are usually
                      incomplete proteins. There is nothing wrong with
                      incomplete proteins however, and there are many healthy,
                      high protein foods that are incomplete proteins. As long
                      as you consume a sufficient variety of incomplete proteins
                      to get all the required amino acids, it is not necessary
                      to specifically eat complete protein foods. In fact,
                      certain high fat red meats for example, a common source of
                      complete proteins, can be unhealthy. Below are some
                      examples of high protein foods that are not complete
                      proteins:
                      {partialProteinList.map((food) => {
                        return (
                          <li>
                            <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                            {food}
                          </li>
                        );
                      })}
                    </p>
                    <p className="text-muted">
                      As can be seen, there are many different foods a person
                      can consume to meet their RDA of protein. The examples
                      provided above do not constitute an exhaustive list of
                      high protein or complete protein foods. As with everything
                      else, balance is important, and the examples provided
                      above are an attempt at providing a list of healthier
                      protein options (when consumed in moderation).
                    </p>
                  </ul>

                  <h4 className="card-title">
                    Amount of protein in common food
                  </h4>
                  <ul className="list-unstyled text-muted">
                    <div className="table-responsive shadow rounded mt-4">
                      <table className="table table-center invoice-tb mb-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "60px" }}
                            >
                              Food
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              Protein Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {proteinAmountFood.map((r) => {
                            return (
                              <tr>
                                <th scope="row" className="text-start p-3">
                                  {r.name}
                                </th>
                                <td className="text-start p-3">{r.amount}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </ul>

                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
