import { useState, useEffect } from "react";
import { loadConnectAndInitialize } from "@stripe/connect-js";
import { getAccountSession } from "../../../utils/stripe.service";

export const useStripeConnect = (connectedAccountId) => {
  const [stripeConnectInstance, setStripeConnectInstance] = useState();

  useEffect(() => {
    if (connectedAccountId) {
      const fetchClientSecret = async () => {
        // Get Session
        let data = {
          account: connectedAccountId,
        };

        // Make Call
        try {
          const response = await getAccountSession(data);
          const { client_secret: clientSecret } = response.data;
          return clientSecret;
        } catch (err) {
          throw ("An error occurred: ", err);
        }
      };

      setStripeConnectInstance(
        loadConnectAndInitialize({
          publishableKey: process.env.REACT_APP_STRIPE_PUBLIC_KEY,
          fetchClientSecret,
          appearance: {
            overlays: "dialog",
            variables: {
              colorPrimary: "#202942",
              buttonPrimaryColorBackground: "#386cf0",
              formBackgroundColor: "#ffffff",
              badgeSuccessColorText: "#386cf0",
            },
          },
        }),
      );
    }
  }, [connectedAccountId]);

  return stripeConnectInstance;
};

export default useStripeConnect;
