import React, { Fragment, useEffect, useState } from "react";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import OffcanvasSearchbar from "../../components/offcanvas/searchbar/offcanvas-searchbar";
import BookAppointmentForm from "../../components/forms/book-appointment-form/BookAppointmentForm";
import { services } from "../../data/data";
import AboutBookAppointment from "../../components/aboutus-mini/book-appointment";
import ServiceInvoice from "../../components/invoice/service-invoice";
import GPSearchFields from "../../components/services-search/gp-services-search";
import GPAboutUsMini from "../../components/aboutus-mini/gp-aboutus-mini";

function GPServices() {
  let [showInvoice, setShowInvoice] = useState(false);
  const [serviceSearchData, setServiceSearchData] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [paymentData, setPaymentData] = useState(null);
  const [showGPAboutUs, setShowGPAboutUs] = useState(false);

  // Change Url
  useEffect(() => {
    console.log(window.location.hash);
    if (window.location.hash === "#appointment") {
      // window.history.replaceState(null, null, "/book/services/gp/appointment");
    } else if (window.location.pathname === "#invoice") {
      window.history.replaceState(null, null, "/book/services/gp/invoice");
    }
  });

  // Use Effect
  useEffect(() => {
    setShowInvoice(false);
  }, []);

  // Handlers
  const handleServiceSearch = (selectionData) => {
    setServiceSearchData(selectionData);
    setShowGPAboutUs(true);
  };

  const handleOrderData = (orderData) => {
    setOrderData(orderData);
  };

  const handlePaymentData = (paymentData) => {
    setPaymentData(paymentData);
    setShowInvoice(true);
    window.history.replaceState(null, null, "/book/services/gp/invoice");
  };

  // BookFormProps
  let bookingProps = {
    serviceSearchData: serviceSearchData,
    handleOrderData: handleOrderData,
    handlePaymentData: handlePaymentData,
  };

  return (
    <Fragment>
      <Header />
      <div
        style={{
          backgroundImage: `url(assets/images/doctorLook.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
        }}
      >
        <section className="bg-half-170 d-table w-100" id="home">
          <div className="bg-overlay bg-overlay-dark"></div>
          <div className="container">
            <div className="row justify-content-center mt-5">
              <div className="col-xl-10">
                <div
                  className="heading-title text-center"
                  style={{ marginTop: 150 }}
                >
                  {/*<img src="assets/images/logo-icon.png" height="100" alt="" />*/}
                  <h4 className="heading fw-bold text-white title-dark mt-3 mb-4">
                    Book Your GP Appointment
                  </h4>
                  <p className="para-desc mx-auto text-white-50 mb-0">
                    Extensive network, highly rated practitioners, convenient
                    locations and tele-consultation options!
                  </p>
                  <GPSearchFields serviceSearchData={handleServiceSearch} />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="section mt-0 pb-0" id="appointment">
        <div className="container mt-0">
          <div className="row align-items-lg-end">
            {showInvoice ? (
              <ServiceInvoice data={paymentData} id="invoice" />
            ) : (
              [
                showGPAboutUs ? <GPAboutUsMini /> : null,
                <AboutBookAppointment />,
                <BookAppointmentForm {...bookingProps} />,
              ]
            )}
          </div>
        </div>
      </section>
      <section className="section mt-0 pb-5">
        <div className="container mt-0">
          <div className="row justify-content-center">
            <div className="col-12">
              <div className="section-title mb-4 pb-2 text-center">
                <span
                  className="badge rounded-pill bg-soft-primary mb-3"
                  style={{ fontSize: "large" }}
                >
                  Our Medical Services
                </span>
                {/*<h4 className="title mb-4">Our Medical Services</h4>*/}
                <p className="text-muted mx-auto para-desc mb-0">
                  We pride on making your service appointment seamless, secure,
                  and reliable!
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            {services.map((serv, index) => {
              return (
                <div className="col-xl-3 col-md-4 col-12 mt-5">
                  <div className="card features feature-primary bg-transparent border-0">
                    <div className="icon text-center rounded-md">
                      {serv.image ? (
                        <img
                          src={serv.imagePath}
                          className="avatar avatar-ex-small rounded"
                          alt=""
                          // style={{ width: 50, height: 50 }}
                        />
                      ) : (
                        <i className={`${serv.icon} h3 mb-0`}></i>
                      )}
                    </div>
                    <div className="card-body p-0 mt-3">
                      <a href="departments.html" className="title text-dark h5">
                        {serv.name}
                      </a>
                      <p className="text-muted mt-3">{serv.description}</p>
                      {/*<a href="departments.html" className="link">*/}
                      {/*  Read More{" "}*/}
                      {/*  <i className="ri-arrow-right-line align-middle"></i>*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <Footer />
      <OffcanvasSearchbar />
    </Fragment>
  );
}

export default GPServices;
