// two-field-search
import React, { useState } from "react";
import "./nhs-bar.css";
import { Grid, Switch } from "@mui/material";

function NHSBar({ serviceSearchData }) {
  // States
  const [isRepeat, setIsRepeat] = React.useState({
    state: true,
  });
  const [nhsAuthenticated, setNhsAuthenticated] = useState(false);
  const [selectedGp, setSelectedGp] = useState(null);

  // Handler
  const handleToggleChange = (name) => (event) => {
    setIsRepeat({ ...isRepeat, [name]: event.target.checked });
  };

  // GP Selection

  // Search

  // Metadata

  // Props for Child
  let props = {};

  return (
    <div className="col-md-12 mt-4 mt-sm-0 pt-2 pt-sm-0">
      <div className="flex mt-4 pt-2">
        <form className="custom-form rounded text-start shadow p-4 bg-white-50">
          <div className="row">
            <div className="flex-column col-lg-2 col-sm-12 mt-4 mt-sm-0">
              <div
                className="input-group bg-transparent border-0 rounded"
                style={{ opacity: 1, justifyContent: "center" }}
              >
                <img
                  className="avatar avatar-ex-small rounded"
                  src="assets/images/NHS.png"
                  style={{ width: "max-content" }}
                ></img>
              </div>
            </div>
            <div className="flex-column col-lg-3 col-sm-12 mt-4 mt-sm-0">
              <div className="d-grid d-md-block">
                <div
                  className="input-group bg-transparent border-0 rounded"
                  style={{ opacity: 1, justifyContent: "center" }}
                >
                  <button
                    type="button"
                    onClick={() => {
                      setNhsAuthenticated(true);
                    }}
                    className="btn btn-primary w-75"
                  >
                    NHS Login
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-column col-lg-3 col-sm-12 mt-4 mt-sm-0">
              <div className="d-grid d-md-block">
                <div
                  className="input-group bg-transparent border-0 rounded"
                  style={{ opacity: 1, justifyContent: "center" }}
                >
                  <button
                    type="button"
                    disabled={!nhsAuthenticated}
                    onClick={() => {
                      setSelectedGp({ a: "b" });
                    }}
                    className="btn btn-primary w-75"
                  >
                    Pick Your GP
                  </button>
                </div>
              </div>
            </div>
            <div className="flex-column col-lg-3 col-sm-12 mt-4 mt-sm-0">
              <div className="d-grid d-md-block">
                <div
                  className="input-group bg-transparent border-0 rounded"
                  style={{ opacity: 1, justifyContent: "center" }}
                >
                  <button
                    type="button"
                    disabled={!nhsAuthenticated || !selectedGp}
                    onClick={() => {}}
                    className="btn btn-primary w-75"
                  >
                    Book
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="bg-transparent text-center" style={{ width: "100%" }}>
            <Grid
              className="flex mt-1"
              component="label"
              container
              alignItems="center"
              spacing={1}
              style={{ width: "1000", justifyContent: "center" }}
            >
              <Grid item>One Time</Grid>
              <Grid item>
                <Switch
                  checked={isRepeat.state}
                  onChange={handleToggleChange("state")}
                  value="state"
                />
              </Grid>
              <Grid item>Repeat</Grid>
            </Grid>
          </div>
        </form>
      </div>
    </div>
  );
}

export default NHSBar;
