// our mission, vision, goal
import React from "react";

const GPAboutUsMini = () => {
  return (
    <section className="section py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex">
              <i className="uil uil-stethoscope h1 mb-0 text-primary"></i>
              <div className="ms-3 ms-lg-4">
                <h5>CREDENTIALS</h5>
                <p className="text-muted mb-0">
                  Dr. Morgan is a highly experienced private GP and Emergency
                  Medicine Doctor with 20 years of experience, 11 years of which
                  specializes in Aesthetics. She specializes in Botulinum Toxin
                  Fillers and other specialized aesthetic treatments.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="d-flex">
              <i className="uil uil-book h1 mb-0 text-primary"></i>
              <div className="ms-3 ms-lg-4">
                <h5>PHILOSOPHY</h5>
                <p className="text-muted mb-0">
                  Dr. Morgan understands the importance of confidence in one’s
                  appearance and collaborates with patients to enhance their
                  natural beauty, helping them become the best version of
                  themselves.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="d-flex">
              <i className="uil uil-gift h1 mb-0 text-primary"></i>
              <div className="ms-3 ms-lg-4">
                <h5>OFFERING</h5>
                <p className="text-muted mb-0">
                  Dr. Morgan is highly experienced in the aesthetic field,
                  offering a wide range of treatments focused on non-surgical
                  facial rejuvenation with dermal fillers and toxins.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GPAboutUsMini;
