import axios from "axios";

const baseUrl = process.env.REACT_APP_STRAPI_BASE_URL;

export async function getBlogs() {
  return axios.get(`${baseUrl}`);
}

export async function getBlogById(id) {
  return axios.get(`${baseUrl}/${id}`);
}
