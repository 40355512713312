import axios from "axios";

const baseUrl = process.env.REACT_APP_backofficems;

export async function postOrder(data) {
  return axios.post(`${baseUrl}/api/order/add`, data);
}

export async function getOrder(id) {
  return axios.get(`${baseUrl}/api/order/data/${id}`);
}

export async function postOrderPayment(data) {
  return axios.post(`${baseUrl}/api/order-payment/add`, data);
}

export async function postProductOrder(data) {
  return axios.post(`${baseUrl}/api/order-product/add`, data);
}

export async function getOrderProduct(id) {
  return axios.get(`${baseUrl}/api/order-product/data/${id}`);
}

export async function postOrderProductPayment(data) {
  return axios.post(`${baseUrl}/api/order-product-payment/add`, data);
}
