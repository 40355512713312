export const ziplaneRxCookies = [
  {
    name: "_accessToken",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "customerId",
    explanation: "Functional cookie to identify you.",
    party: "First-Party",
  },
  {
    name: "user_id",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "role_id",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "p_nbr",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "business_enrolled_in",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "mongoSearch",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "practitioners",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "email",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "sub_role_id",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "nhsScore",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "auth0Sub",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "third",
  },
  {
    name: "odsCode",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "entity_webstore_url",
    explanation:
      "Functional cookie to keep you logged in and use our services.",
    party: "First-Party",
  },
  {
    name: "cookie_consent_level",
    explanation:
      "This cookie is used to keep keep track of your cookie consent level.",
    party: "First-Party",
  },
  {
    name: "cookie_consent_user_accepted",
    explanation:
      "This cookie determine if you have or have not accepted to our cookie consent.",
    party: "First-Party",
  },
  {
    name: "cookie_consent_user_consent_token",
    explanation: "This cookie is used to identify your cookie consent.",
    party: "First-Party",
  },
  {
    name: "OptanonConsent",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "__stripe_mid",
    explanation: "Functional cookie used for payment processing.",
    party: "Third-Party",
  },
  {
    name: "__stripe_sid",
    explanation: "Functional cookie used for payment processing.",
    party: "Third-Party",
  },
  {
    name: "_ab",
    explanation: "Functional cookie used for payment processing.",
    party: "Third-Party",
  },
  {
    name: "_mf",
    explanation: "Functional cookie used for payment processing.",
    party: "Third-Party",
  },
  {
    name: "id",
    explanation: "Functional cookie used for payment processing.",
    party: "Third-Party",
  },
  {
    name: "code",
    explanation: "Functional cookie to authenticate and verify bank account.",
    party: "Third-Party",
  },
  {
    name: "_ga",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_ga_QKMSDV5369",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_gcl_au",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_gid",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_hp2_id",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_hp2_props",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_hp2_ses_props",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_legacy_auth0.is.authenticated",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_mkto_trk",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_okta_attribution",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_okta_original_attribution",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_okta_session_attribution",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "_rdt_uuid",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "auth0",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "auth0.is.authenticated",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "auth0_compat",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "cf_clearance",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "did",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "did_compat",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "docs_current_tenant",
    explanation: "This cookie is used for authentication.",
    party: "Third-Party",
  },
  {
    name: "radar-installId",
    explanation: "This cookie is used to provide location services.",
    party: "Third-Party",
  },
  {
    name: "radar-sessionId",
    explanation: "This cookie is used to provide location services.",
    party: "Third-Party",
  },
  {
    name: "radar-deviceId",
    explanation: "This cookie is used to provide location services.",
    party: "Third-Party",
  },
];

// export const ziplaneRxCookies = [
//   // {
//   //   name: "__utma/b/z (Third-party)",
//   //   explanation:
//   //     "These cookies are used by our analytics software to monitor which pages are visited and allow us to make improvements to the website by analyzing visitors interest and behavior. All data collected is anonymous.",
//   // },
//   // {
//   //   name: "__utmc (Third-party)",
//   //   explanation:
//   //     "These cookies are used by our analytics software to monitor which pages are visited and allow us to make improvements to the website by analyzing visitors interest and behavior. All data collected is anonymous.",
//   // },
//   // {
//   //   name: "__check_security_restriction",
//   //   explanation:
//   //     "This cookie is used to control which tasks you as a user are allowed to perform.",
//   // },
//   {
//     name: "__nhs-number",
//     explanation: "This cookie is used to store your NHS number. (Coming Soon)",
//   },
//   {
//     name: "__token",
//     explanation: "This cookie is used to keep you logged in.",
//   },
//   {
//     name: "__accessToken",
//     explanation: "This cookie is used to keep you logged in.",
//   },
//   {
//     name: "__token_expires_in",
//     explanation: "This cookie is used to keep you logged in.",
//   },
//   {
//     name: "__userId",
//     explanation: "This cookie is used to keep you logged in.",
//   },
//   {
//     name: "__customerId",
//     explanation: "This cookie is used to keep you logged in.",
//   },
//   {
//     name: "cookie_consent_level",
//     explanation:
//       "This cookie is used to keep keep track of your cookie consent level.",
//   },
//   {
//     name: "cookie_consent_user_accepted",
//     explanation:
//       "This cookie determine if you have or have not accepted to our cookie consent.",
//   },
//   {
//     name: "cookie_consent_user_consent_token",
//     explanation: "This cookie is used to identify your cookie consent.",
//   },
// ];

export const supportPortalCookies = [
  // {
  //   name: "__patientId",
  //   explanation: "This cookie is used to store your EMIS Web patient ID.",
  // },
  {
    name: "__token",
    explanation: "This cookie is used to keep you logged in.",
  },
  {
    name: "__token_expires_in",
    explanation: "This cookie is used to keep you logged in.",
  },
  {
    name: "__userId",
    explanation: "This cookie is used to keep you logged in.",
  },
  {
    name: "cookie_consent_level",
    explanation:
      "This cookie is used to keep keep track of your cookie consent level.",
  },
  {
    name: "cookie_consent_user_accepted",
    explanation:
      "This cookie determine if you have or have not accepted to our cookie consent.",
  },
  {
    name: "cookie_consent_user_consent_token",
    explanation: "This cookie is used to identify your cookie consent.",
  },
  // {
  //   name: "_ga (Third-party)",
  //   explanation:
  //     "These cookies are used by our analytics software to monitor which pages are visited and allow us to make improvements to the website by analyzing visitors interest and behavior. All data collected is anonymous.",
  // },
  // {
  //   name: "_gat (Third-party)",
  //   explanation:
  //     "These cookies are used by our analytics software to monitor which pages are visited and allow us to make improvements to the website by analyzing visitors interest and behavior. All data collected is anonymous.",
  // },
  // {
  //   name: "_git (Third-party)",
  //   explanation:
  //     "These cookies are used by our analytics software to monitor which pages are visited and allow us to make improvements to the website by analyzing visitors interest and behavior. All data collected is anonymous.",
  // },
  // {
  //   name: "_RequestverificationToken",
  //   explanation: "This cookie helps us to keep the site secure.",
  // },
  // {
  //   name: "cookies_consent",
  //   explanation:
  //     "This cookie records whether this is your first visit to our site to improve your experience.",
  // },
  // {
  //   name: "ARRAaffinity",
  //   explanation:
  //     "This cookie helps us identify which of our application instances you have visited in the past.",
  // },
];
