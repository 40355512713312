import React, { useState } from "react";

export default function CounterTwo({ productId, setProductCount }) {
  let [count, setCount] = useState({});

  let increment = () => {
    if (count[productId]) {
      let temp = count[productId];
      setCount({ ...count, [productId]: temp + 1 });
      setProductCount({ ...count, [productId]: temp + 1 });
    } else {
      setCount({ ...count, [productId]: 1 });
      setProductCount({ ...count, [productId]: 1 });
    }
  };

  let decrement = () => {
    if (count[productId] && count[productId] > 0) {
      let temp = count[productId];
      setCount({ ...count, [productId]: temp - 1 });
      setProductCount({ ...count, [productId]: temp - 1 });
    }
  };

  return (
    <div className="qty-icons">
      <button
        className="btn btn-pills btn-icon btn-primary minus"
        onClick={() => decrement()}
      >
        -
      </button>
      <input
        min="0"
        name="quantity"
        value={count[productId] || 0}
        type="number"
        className="btn btn-pills btn-icon btn-primary qty-btn quantity"
        readOnly
      />
      <button
        className="btn btn-pills btn-icon btn-primary plus"
        onClick={() => increment()}
      >
        +
      </button>
    </div>
  );
}
