import "./App.css";
import Services from "./pages/book/Services";
import { Route, Routes } from "react-router-dom";
import AboutUs from "./pages/aboutus/aboutus";
import PatientProfile from "./pages/patient/patient-profile";
import Terms from "./pages/aboutus/terms";
import Privacy from "./pages/aboutus/privacy";
import Cookies from "./pages/aboutus/cookies";
import Bmi from "./pages/healthyliving/bmi";
import WaterIntake from "./pages/healthyliving/waterintake";
import Bmr from "./pages/healthyliving/bmr";
import Carbs from "./pages/healthyliving/carbs";
import Heart from "./pages/healthyliving/heart";
import Protein from "./pages/healthyliving/protein";
import PharmacyProductsSearch from "./pages/shop/pharmacy-shop";
import Blogs from "./pages/blog/blogs";
import BlogDetail from "./pages/blog/blog-detail";
import PharmacyProductDetail from "./pages/shop/pharmacy-product-detail";
import ShopCart from "./pages/shop/pharmacy-shop-cart";
import { useEffect, useState } from "react";
import PharmacyShopCategoriesMain from "./pages/shop/pharmacy-shop-categories-main";
import Prescriptions from "./pages/book/Prescriptions";
import ServiceInvoicePage from "./pages/invoice/services-invoice";
import ProductsInvoicePage from "./pages/invoice/products-invoice";
import GPServices from "./pages/book/GPServices";
import Login from "./pages/login/patient-login";
import PatientPostAuthenticate from "./pages/login/patient-post-authenticate";
import PractitionerLogin from "./pages/login/practitioner-login";
import StripeOnboarding from "./pages/stripe/onboarding";
import StripeSubscriptionCheckout from "./pages/stripe/subscription-checkout";
import StripeCheckoutExit from "./pages/stripe/stripe-checkout-exit";
import Pharmacy from "./pages/pharmacy/pharmacy";

function App() {
  // Cart
  let [cart, setCart] = useState(null);
  useEffect(() => {
    if (!cart) {
      setCart({});
    }
  });
  // Cart Functions
  const addToCart = (productId, productData, quantity) => {
    if (cart[productId]) {
      cart[productId]["quantity"] += quantity && quantity !== 0 ? quantity : 1;
    } else {
      productData["quantity"] = quantity && quantity !== 0 ? quantity : 1;
      cart[productId] = productData;
    }
  };

  const removeFromCart = (productId, productData) => {
    if (cart[productId]) {
      cart[productId]["quantity"] -= 1;
    } else {
      productData["quantity"] = 1;
      cart[productId] = productData;
    }
  };

  const clearCart = () => {
    setCart({});
  };

  return (
    <div className="App">
      <Routes>
        {/*Login and Authenticate*/}
        <Route exact path="/patient/login" element={<Login />} />
        <Route
          exact
          path="/practitioner/login"
          element={<PractitionerLogin />}
        />
        <Route
          exact
          path="/patient/authenticate"
          element={<PatientPostAuthenticate />}
        />
        {/*Landing*/}
        <Route exact path="/" element={<Services />} />
        <Route exact path="/home" element={<Services />} />
        <Route exact path="/book" element={<Services />} />
        <Route exact path="/book/services" element={<Services />} />
        <Route exact path="/book/services/gp" element={<GPServices />} />
        <Route
          exact
          path="/book/services/invoice"
          element={<ServiceInvoicePage />}
        />
        <Route exact path="/book/prescriptions" element={<Prescriptions />} />
        <Route exact path="/book/:somePath" element={<Services />} />
        {/*Shop*/}
        <Route exact path="/shop/search" element={<PharmacyProductsSearch />} />
        <Route
          exact
          path="/shop/:class"
          element={<PharmacyShopCategoriesMain addToCart={addToCart} />}
        />
        <Route
          exact
          path="/shop/products/:productId"
          element={<PharmacyProductDetail addToCart={addToCart} />}
        />
        <Route
          exact
          path="/shop/cart"
          element={
            <ShopCart
              cart={cart}
              removeFromCart={removeFromCart}
              clearCart={clearCart}
            />
          }
        />
        <Route
          exact
          path="/shop/products/invoice"
          element={
            <ProductsInvoicePage
              cart={cart}
              removeFromCart={removeFromCart}
              clearCart={clearCart}
            />
          }
        />
        {/*<Route*/}
        {/*    exact*/}
        {/*    path="/shop/test/:category"*/}
        {/*    element={<PharmacyShopCategories />}*/}
        {/*/>*/}
        {/*Reference*/}
        <Route exact path="/aboutus" element={<AboutUs />} />
        <Route exact path="/aboutus/terms" element={<Terms />} />
        <Route exact path="/aboutus/privacy" element={<Privacy />} />
        <Route exact path="/aboutus/cookies" element={<Cookies />} />
        <Route exact path="/patient-profile" element={<PatientProfile />} />
        {/*Healthy Living*/}
        <Route path="/healthy-living/bmi-calculator" element={<Bmi />} />
        <Route
          path="/healthy-living/water-intake-calculator"
          element={<WaterIntake />}
        />
        <Route exact path="/healthy-living/bmr-calculator" element={<Bmr />} />
        <Route
          exact
          path="/healthy-living/carb-calculator"
          element={<Carbs />}
        />
        <Route
          exact
          path="/healthy-living/heart-rate-calculator"
          element={<Heart />}
        />
        <Route
          exact
          path="/healthy-living/protein-calculator"
          element={<Protein />}
        />
        {/*Blogs*/}
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/blog-detail/:id" element={<BlogDetail />} />
        {/*  Stripe*/}
        <Route exact path="/stripe/onboarding" element={<StripeOnboarding />} />
        <Route
          exact
          path="/stripe/subscription/checkout"
          element={<StripeSubscriptionCheckout />}
        />
        <Route
          exact
          path="/stripe/subscription?"
          element={<StripeCheckoutExit />}
        />
        {/*  Pharmacy Webstore*/}
        {/*<Route exact path="org/:entity_webstore_url" element={<Pharmacy />} />*/}
      </Routes>
    </div>
  );
}

export default App;
