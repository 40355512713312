import React, { Fragment, useEffect, useState } from "react";
import { Link, useParams, useSearchParams } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";

import Carousel from "react-bootstrap/Carousel";
import { FiEye } from "react-icons/fi";
import "tiny-slider/dist/tiny-slider.css";
import Header from "../../components/header/header";
import { mongoSearchActiveProducts } from "../../utils/referencedata.services";
import Pagination from "../../components/pagination";

export default function PharmacyProductsSearch({ queryString }) {
  // Metadata
  let params = useParams();
  let className = params.class || "search";
  let settings = {
    container: ".slider-range-four",
    items: 3,
    controls: false,
    mouseDrag: true,
    loop: true,
    rewind: true,
    autoplay: true,
    autoplayButtonOutput: false,
    autoplayTimeout: 3000,
    navPosition: "bottom",
    speed: 400,
    gutter: 24,
    responsive: {
      992: {
        items: 4,
      },

      767: {
        items: 2,
      },

      320: {
        items: 1,
      },
    },
  };

  // Search
  let [searchString, setSearchString] = useSearchParams();
  // Pagination Variables
  let [index, setIndex] = useState(0);
  let [productsData, setProductsData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(16);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = productsData?.slice(
    indexOfFirstRecord,
    indexOfLastRecord,
  );
  const nPages = Math.ceil(productsData?.length / recordsPerPage) || 0;

  // Products
  useEffect(() => {
    let criteria = searchString.get("name");
    mongoSearchActiveProducts(criteria).then((respose) => {
      if (respose.data) {
        setProductsData(
          respose.data.sort((a, b) =>
            a.labels_fulldescription.localeCompare(b.labels_fulldescription),
          ),
        );
      }
    });
  }, [searchString]);

  // Handlers
  let handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <Fragment>
      <Header />

      <section className="home-slider position-relative">
        <Carousel
          activeIndex={index}
          onSelect={handleSelect}
          indicators={false}
          interval={2000}
        >
          <Carousel.Item>
            <div className="carousel-item active">
              <div
                className="bg-half-170 d-table align-items-center w-100"
                style={{
                  backgroundImage: `url(assets/images/healthyliving/pills.jpg)`,
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  overflow: "scroll",
                }}
              >
                <section className="bg-overlay bg-overlay-dark">
                  <div className="container">
                    <div className="row mt-5">
                      <div className="col-lg-12">
                        <div className="heading-title">
                          {/*<h1 className="fw-bold mb-4">{metadata.name}</h1>*/}
                          {/*/!*<p className="para-desc mb-0">ZipLaneRx</p>*!/*/}

                          {/*<div className="mt-4 pt-2">*/}
                          {/*  <Link to="#" className="btn btn-primary">*/}
                          {/*    Shop now*/}
                          {/*  </Link>*/}
                          {/*</div>*/}
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </Carousel.Item>
        </Carousel>
      </section>

      <section className="section">
        <div className="container">
          <div className="row">
            {currentRecords?.length === 0 ? (
              <div className="col-12">
                <h4 className="mb-0">No Products Found</h4>
              </div>
            ) : (
              <div className="col-12">
                <h4 className="mb-0">Check out our Products</h4>
              </div>
            )}
          </div>

          <div className="row">
            {currentRecords?.map((item, index) => {
              if (item.price_trade > 0) {
                return (
                  <div
                    className="col-lg-3 col-md-6 col-12 mt-4 pt-2"
                    key={index}
                  >
                    <div className="card shop-list border-0">
                      <ul className="label list-unstyled mb-0">
                        <li>
                          <Link
                            to=""
                            className="badge rounded-pill badge-success"
                          >
                            Featured
                          </Link>
                        </li>
                      </ul>
                      <div className="shop-image position-relative overflow-hidden rounded shadow">
                        <Link to={`/shop/products/${item.productId}`}>
                          <img
                            src={`data:image/gif;base64,${item.image}`}
                            className="img-fluid"
                            alt=""
                            style={{ width: 90, height: 125 }}
                          />
                        </Link>
                        <ul className="list-unstyled shop-icons">
                          {/*<li>*/}
                          {/*  <Link*/}
                          {/*    to="#"*/}
                          {/*    className="btn btn-icon btn-pills btn-soft-danger"*/}
                          {/*  >*/}
                          {/*    <FiHeart className="icons" />*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                          <li className="mt-2">
                            <Link
                              to={`/shop/products/${item.productId}`}
                              className="btn btn-icon btn-pills btn-soft-primary"
                            >
                              <FiEye className="icons" />
                            </Link>
                          </li>
                          {/*<li className="mt-2">*/}
                          {/*  <Link*/}
                          {/*    to="/pharmacy-shop-cart"*/}
                          {/*    className="btn btn-icon btn-pills btn-soft-warning"*/}
                          {/*  >*/}
                          {/*    <FiShoppingCart className="icons" />*/}
                          {/*  </Link>*/}
                          {/*</li>*/}
                        </ul>

                        {/*<Counter />*/}
                      </div>
                      <div className="card-body content pt-4 p-2">
                        <Link
                          to={`/shop/products/${item.productId}`}
                          className="text-dark product-name h6"
                        >
                          {item.labels_fulldescription}
                        </Link>
                        <div className="d-flex justify-content-between mt-1">
                          <h6 className="text-muted small font-italic mb-0 mt-1">
                            £ {item.price_trade ? item.price_trade : 0}
                          </h6>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              path={`/shop/${className}?name=${searchString.get("name")}`}
            />
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
