// footer component
import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    return (
      <footer className="">
        <div className="container" style={{ height: 5 }}>
          <div className="row">
            <div className="col-xl-12 col-lg-12 mb-0 mb-md-4 pb-0 pb-md-2 mt-0 pt-0">
              {/*<Link to="#" className="logo-footer">*/}
              {/*  <img*/}
              {/*    src={"assets/images/logo-icon.png"}*/}
              {/*    height="100"*/}
              {/*    alt=""*/}
              {/*    style={{ marginLeft: 0, marginBottom: 0, paddingBottom: 0 }}*/}
              {/*  />*/}
              {/*</Link>*/}
              <p className="me-xl-5" style={{ marginTop: -20 }}>
                Revolutionizing digital healthcare by fostering inclusivity,
                accessibility and empowerment for customers and healthcare
                practitioners
              </p>
            </div>

            {/*<div className="col-xl-7 col-lg-8 col-md-12">*/}
            {/*  <div className="row">*/}
            {/*    <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">*/}
            {/*      /!*<h5 className="footer-head">Company</h5>*!/*/}
            {/*      /!*<ul className="list-unstyled footer-list mt-4">*!/*/}
            {/*      /!*  <li>*!/*/}
            {/*      /!*    <a href="/aboutus" className="text-foot">*!/*/}
            {/*      /!*      <i className="mdi mdi-chevron-right me-1"></i> About us*!/*/}
            {/*      /!*    </a>*!/*/}
            {/*      /!*  </li>*!/*/}
            {/*      /!*<li>*!/*/}
            {/*      /!*  <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*    <i className="mdi mdi-chevron-right me-1"></i> Services*!/*/}
            {/*      /!*  </a>*!/*/}
            {/*      /!*</li>*!/*/}
            {/*      /!*<li>*!/*/}
            {/*      /!*  <a href="doctor-team-two.html" className="text-foot">*!/*/}
            {/*      /!*    <i className="mdi mdi-chevron-right me-1"></i> Team*!/*/}
            {/*      /!*  </a>*!/*/}
            {/*      /!*</li>*!/*/}
            {/*      /!*<li>*!/*/}
            {/*      /!*  <a href="blog-detail.html" className="text-foot">*!/*/}
            {/*      /!*    <i className="mdi mdi-chevron-right me-1"></i> Project*!/*/}
            {/*      /!*  </a>*!/*/}
            {/*      /!*</li>*!/*/}
            {/*      /!*<li>*!/*/}
            {/*      /!*  <a href="blogs.html" className="text-foot">*!/*/}
            {/*      /!*    <i className="mdi mdi-chevron-right me-1"></i> Blog*!/*/}
            {/*      /!*  </a>*!/*/}
            {/*      /!*</li>*!/*/}
            {/*      /!*<li>*!/*/}
            {/*      /!*  <a href="login.html" className="text-foot">*!/*/}
            {/*      /!*    <i className="mdi mdi-chevron-right me-1"></i> Login*!/*/}
            {/*      /!*  </a>*!/*/}
            {/*      /!*</li>*!/*/}
            {/*      /!*</ul>*!/*/}
            {/*    </div>*/}

            {/*    <div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">*/}
            {/*      /!*  <h5 className="footer-head">Departments</h5>*!/*/}
            {/*      /!*  <ul className="list-unstyled footer-list mt-4">*!/*/}
            {/*      /!*    <li>*!/*/}
            {/*      /!*      <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*        <i className="mdi mdi-chevron-right me-1"></i> Eye Care*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </li>*!/*/}
            {/*      /!*    <li>*!/*/}
            {/*      /!*      <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*        <i className="mdi mdi-chevron-right me-1"></i>{" "}*!/*/}
            {/*      /!*        Psychotherapy*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </li>*!/*/}
            {/*      /!*    <li>*!/*/}
            {/*      /!*      <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*        <i className="mdi mdi-chevron-right me-1"></i> Dental*!/*/}
            {/*      /!*        Care*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </li>*!/*/}
            {/*      /!*    <li>*!/*/}
            {/*      /!*      <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*        <i className="mdi mdi-chevron-right me-1"></i>{" "}*!/*/}
            {/*      /!*        Orthopedic*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </li>*!/*/}
            {/*      /!*    <li>*!/*/}
            {/*      /!*      <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*        <i className="mdi mdi-chevron-right me-1"></i>{" "}*!/*/}
            {/*      /!*        Cardiology*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </li>*!/*/}
            {/*      /!*    <li>*!/*/}
            {/*      /!*      <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*        <i className="mdi mdi-chevron-right me-1"></i>{" "}*!/*/}
            {/*      /!*        Gynecology*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </li>*!/*/}
            {/*      /!*    <li>*!/*/}
            {/*      /!*      <a href="departments.html" className="text-foot">*!/*/}
            {/*      /!*        <i className="mdi mdi-chevron-right me-1"></i> Neurology*!/*/}
            {/*      /!*      </a>*!/*/}
            {/*      /!*    </li>*!/*/}
            {/*      /!*  </ul>*!/*/}
            {/*    </div>*/}

            {/*    /!*<div className="col-md-4 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0">*!/*/}
            {/*    /!*  <h5 className="footer-head">Contact us</h5>*!/*/}
            {/*    /!*  <ul className="list-unstyled footer-list mt-4">*!/*/}
            {/*    /!*    <li className="d-flex align-items-center">*!/*/}
            {/*    /!*      <i*!/*/}
            {/*    /!*        data-feather="mail"*!/*/}
            {/*    /!*        className="fea icon-sm text-foot align-middle"*!/*/}
            {/*    /!*      ></i>*!/*/}
            {/*    /!*      <a*!/*/}
            {/*    /!*        href="mailto:contact@ziplanerx.com"*!/*/}
            {/*    /!*        className="text-foot ms-2"*!/*/}
            {/*    /!*      >*!/*/}
            {/*    /!*        contact@ziplanerx.com*!/*/}
            {/*    /!*      </a>*!/*/}
            {/*    /!*    </li>*!/*/}

            {/*    /!*    <li className="d-flex align-items-center">*!/*/}
            {/*    /!*      <i*!/*/}
            {/*    /!*        data-feather="phone"*!/*/}
            {/*    /!*        className="fea icon-sm text-foot align-middle"*!/*/}
            {/*    /!*      ></i>*!/*/}
            {/*    /!*      <a href="tel:+152534-468-854" className="text-foot ms-2">*!/*/}
            {/*    /!*        +44-79-21-544-654*!/*/}
            {/*    /!*      </a>*!/*/}
            {/*    /!*    </li>*!/*/}

            {/*    /!*    <li className="d-flex align-items-center">*!/*/}
            {/*    /!*      <i*!/*/}
            {/*    /!*        data-feather="map-pin"*!/*/}
            {/*    /!*        className="fea icon-sm text-foot align-middle"*!/*/}
            {/*    /!*      ></i>*!/*/}
            {/*    /!*      <a*!/*/}
            {/*    /!*        href="javascript:void(0)"*!/*/}
            {/*    /!*        className="video-play-icon text-foot ms-2"*!/*/}
            {/*    /!*      >*!/*/}
            {/*    /!*        View on Google map*!/*/}
            {/*    /!*      </a>*!/*/}
            {/*    /!*    </li>*!/*/}
            {/*    /!*  </ul>*!/*/}

            {/*    /!*  /!*<ul className="list-unstyled social-icon footer-social mb-0 mt-4">*!/*!/*/}
            {/*    /!*  /!*  <li className="list-inline-item">*!/*!/*/}
            {/*    /!*  /!*    <a href="#" className="rounded-pill">*!/*!/*/}
            {/*    /!*  /!*      <i*!/*!/*/}
            {/*    /!*  /!*        data-feather="facebook"*!/*!/*/}
            {/*    /!*  /!*        className="fea icon-sm fea-social"*!/*!/*/}
            {/*    /!*  /!*      ></i>*!/*!/*/}
            {/*    /!*  /!*    </a>*!/*!/*/}
            {/*    /!*  /!*  </li>*!/*!/*/}
            {/*    /!*  /!*  <li className="list-inline-item">*!/*!/*/}
            {/*    /!*  /!*    <a href="#" className="rounded-pill">*!/*!/*/}
            {/*    /!*  /!*      <i*!/*!/*/}
            {/*    /!*  /!*        data-feather="instagram"*!/*!/*/}
            {/*    /!*  /!*        className="fea icon-sm fea-social"*!/*!/*/}
            {/*    /!*  /!*      ></i>*!/*!/*/}
            {/*    /!*  /!*    </a>*!/*!/*/}
            {/*    /!*  /!*  </li>*!/*!/*/}
            {/*    /!*  /!*  <li className="list-inline-item">*!/*!/*/}
            {/*    /!*  /!*    <a href="#" className="rounded-pill">*!/*!/*/}
            {/*    /!*  /!*      <i*!/*!/*/}
            {/*    /!*  /!*        data-feather="twitter"*!/*!/*/}
            {/*    /!*  /!*        className="fea icon-sm fea-social"*!/*!/*/}
            {/*    /!*  /!*      ></i>*!/*!/*/}
            {/*    /!*  /!*    </a>*!/*!/*/}
            {/*    /!*  /!*  </li>*!/*!/*/}
            {/*    /!*  /!*  <li className="list-inline-item">*!/*!/*/}
            {/*    /!*  /!*    <a href="#" className="rounded-pill">*!/*!/*/}
            {/*    /!*  /!*      <i*!/*!/*/}
            {/*    /!*  /!*        data-feather="linkedin"*!/*!/*/}
            {/*    /!*  /!*        className="fea icon-sm fea-social"*!/*!/*/}
            {/*    /!*  /!*      ></i>*!/*!/*/}
            {/*    /!*  /!*    </a>*!/*!/*/}
            {/*    /!*  /!*  </li>*!/*!/*/}
            {/*    /!*  /!*</ul>*!/*!/*/}
            {/*    /!*</div>*!/*/}
            {/*  </div>*/}
            {/*</div>*/}
          </div>
        </div>

        <div className="container mt-5">
          <div className="pt-4 footer-bar">
            <div className="row align-items-center">
              <div className="col-sm-3">
                <div className="text-sm-start text-center">
                  <p className="mb-0">
                    <script>document.write(new Date().getFullYear())</script> ©
                    ZipLaneRx. 2024
                  </p>
                </div>
              </div>

              <div className="col-sm-6 mt-4 mt-sm-0">
                <ul className="list-unstyled footer-list text-center mb-0">
                  <li className="list-inline-item">
                    <Link to="/aboutus" className="text-foot me-2">
                      About
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/aboutus/terms" className="text-foot me-2">
                      Terms
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/aboutus/privacy" className="text-foot me-2">
                      Privacy
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to="/aboutus/cookies" className="text-foot me-2">
                      Cookies
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="col-sm-3 mt-4 mt-sm-0">
                <ul className="list-unstyled footer-list text-center mb-0">
                  <li className="list-inline-item">
                    <img
                      src={"assets/images/support.svg"}
                      className="l-dark"
                      height="75"
                      alt=""
                    />
                    <p className="mb-0">Proud Supporters</p>
                    <p>Community Practitioners</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <a
            href="#body"
            onClick={window.topFunction}
            id="back-to-top"
            className="back-to-top fs-5 rounded-pill text-center bg-primary justify-content-center align-items-center"
          >
            <i data-feather="arrow-up" className="fea icon-sm"></i>
          </a>
        </div>
      </footer>
    );
  }
}

export default Footer;
