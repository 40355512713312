import moment from "moment";

export const emailValidator = (email) => {
  return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
};

export const phoneValidator = (phoneNumber) => {
  return /^[0][0-9]{10}$/.test(phoneNumber);
};

//
export const postalCodeValidator = (postalCode) => {
  return /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9]?[A-Za-z])))) [0-9][A-Za-z]{2})/i.test(
    postalCode,
  );
};

export const birthdateValidator = (date) => {
  const momentDate = moment(date); // specify format and strict parsing
  if (!momentDate.isValid()) return false;
  const today = moment();
  const age = today.diff(momentDate, "years");
  if (age < 18) return false; // check if age is at least 18
  return true;
};
