import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Auth0Provider
    domain={`${process.env.REACT_APP_auth0_domain}`}
    clientId={`${process.env.REACT_APP_auth0_clientId}`}
    authorizationParams={{
      // redirect_uri: `${process.env.REACT_APP_frontoffice_base}/home`,
      audience: process.env.REACT_APP_AUTH_AUD,
    }}
    // redirect_uri={`${process.env.REACT_APP_frontoffice_base}/home`}
  >
    <BrowserRouter basename={`${process.env.REACT_APP_BASE_URL}`}>
      <App />
    </BrowserRouter>
  </Auth0Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
