import React, { Fragment, useEffect, useState } from "react";
import { getCheckoutSession } from "../../utils/stripe.service";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";

export default function StripeSubscriptionCheckout() {
  const [connectedCustomerId, setConnectedCustomerId] = useState();
  const [clientSercret, setClientSecret] = useState();
  const location = useLocation();
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {});

  // Extract query parameters
  useEffect(() => {
    // Extract query parameters
    const searchParams = new URLSearchParams(location.search);
    const customerId = searchParams.get("customer_id");
    if (customerId) {
      setConnectedCustomerId(customerId);
    } else {
      window.open(process.env.REACT_APP_frontoffice_base, "_self");
    }
  }, [location]);

  const fetchClientSecret = async () => {
    try {
      let session = await getCheckoutSession({
        customer_id: connectedCustomerId,
        return_url: `${process.env.REACT_APP_frontoffice_base}/stripe/subscription`,
      });
      session = session.data;
      setClientSecret(session.client_secret);
      return session.client_secret;
    } catch {}
  };

  const options = { fetchClientSecret };

  return (
    <Fragment>
      <div className="container">
        <div className="banner">
          <h2>ZipLaneRx</h2>
          <h6>
            Revolutionizing digital healthcare by fostering inclusivity,
            accessibility and empowerment for customers and healthcare
            practitioners.
          </h6>
        </div>
        <div className="content">
          {connectedCustomerId && (
            <div>
              <h2>Subscribe</h2>
              <EmbeddedCheckoutProvider
                stripe={stripePromise}
                options={options}
              >
                <EmbeddedCheckout />
              </EmbeddedCheckoutProvider>
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
