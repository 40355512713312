// two-field-search
import React, { useEffect, useState } from "react";
import {
  getAllServices,
  getConsentForm,
} from "../../utils/referencedata.services";
import "./services-search.css";
import { Grid, Rating, Switch } from "@mui/material";
import { HashLink } from "react-router-hash-link";
import {
  getPractitionerByOds,
  getPractitionerService,
  searchServicesAndPharmacies,
} from "../../utils/pctmgmt.services";
import AboutUsMini from "../aboutus-mini/aboutus-mini";
import Select from "react-select";

function GPSearchFields({ serviceSearchData }) {
  const getInitialSelection = () => {
    return "Select a Service";
  };
  // TODO: Get ZipCode base on Location
  // let postalCode = "HA8 7HF";

  // States
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(getInitialSelection);
  const [selectedServiceId, setSelectedServiceId] = useState(null);
  const [consentTemplate, setConsentTemplate] = useState(null);
  const [postalCode, setPostalCode] = useState("HA8 7HF");

  // Pharmacy Selection
  const handlePharmacySelection = (data) => {
    console.log(data);
    // Get Service Information
    let practitioner = data?.practitionerData;
    let p_nbr = practitioner?.p_nbr;
    data.practitionerData.business_nm = `${data.practitionerData.business_nm} - Dr. Morgan`;
    getPractitionerService(p_nbr, selectedServiceId).then((response) => {
      data.service = response.data;
      data.consentTemplate = consentTemplate;
      serviceSearchData(data);
    });
  };

  // NHS Toggle
  const [isNhs, setIsNhs] = React.useState({
    state: false,
  });
  const [showPharmacies, setShowPharmacies] = useState(false);
  const [pharmacies, setPharmacies] = useState([]);

  // Get All Services
  useEffect(() => {
    getAllServices().then((response) => {
      setServices(
        response.data
          .sort((a, b) => a.serviceName.localeCompare(b.serviceName))
          .filter((srvc) => srvc.serviceSubCategory === "GP"),
      );
    });
  }, []);

  // Filter
  function getFilteredServices() {
    let res = services;
    if (isNhs.state) {
      res = services.filter((serv) => serv.isNhs === 1);
    } else {
      res = services.filter((serv) => serv.isPrivate === 1);
    }
    return res;
  }

  const handleChange = (name) => (event) => {
    setIsNhs({ ...isNhs, [name]: event.target.checked });
  };

  const selectService = (e) => {
    // Set Value
    setSelectedService(e.serviceName);
    // Find and Set
    let service = services.find((serv) => serv.serviceName === e.serviceName);
    setSelectedServiceId(service.serviceId);
    // Find and Set Template
    if (service.templateInd === 1 && service.templateId) {
      getConsentForm(service.templateId).then((response) => {
        setConsentTemplate(response.data);
      });
    } else {
      setConsentTemplate(null);
    }
  };

  // Search
  const searchPharmacies = () => {
    // Payload
    let payload = {
      component: "SEARCH",
      postalCode: postalCode,
      serviceNm: selectedService,
      serviceType: isNhs.state ? "NHS" : "Non-NHS",
    };

    // Make Call
    let results;
    searchServicesAndPharmacies(payload).then((response) => {
      results = response.data;
      if (results.data.length > 0) {
        setShowPharmacies(true);
        setPharmacies(results.data);
      }
    });
  };

  // Metadata
  let metadata = {
    serviceName: selectedService,
    serviceId: selectedServiceId
      ? selectedServiceId.toLowerCase()
      : selectedServiceId,
    isNhs: isNhs.state,
  };

  // Props for Child
  let props = {
    pharmacies: pharmacies,
    metadata: metadata,
    handleSelection: handlePharmacySelection,
  };

  return (
    <div className="overflow-auto">
      <div className="flex mt-4 pt-2">
        <form className="rounded text-start shadow p-4 bg-white-50">
          <div className="row align-items-center">
            <div className="col-md mt-4 mt-sm-0">
              <div
                className="input-group bg-white border rounded"
                style={{ opacity: 1, zIndex: 3 }}
              >
                <span className="input-group-text border-0">
                  <i className="ri-health-book-line text-primary h5 fw-normal mb-0"></i>
                </span>
                <Select
                  className="form-control border-0"
                  options={services ? getFilteredServices() : []}
                  onChange={selectService}
                  value={services.find(
                    (serv) => serv.serviceName === selectedService,
                  )}
                  getOptionLabel={(option) => option.serviceName.toUpperCase()}
                  // getOptionValue={(option) => option}
                  menuPosition="fixed"
                  isSearchable={true}
                  autoFocus={true}
                  placeholder="Select Service"
                />
              </div>
            </div>
            <div className="col-md">
              <div
                className="input-group bg-white border rounded"
                style={{ opacity: 1, zIndex: 2 }}
              >
                <span className="input-group-text border-0">
                  <i className="ri-map-pin-line text-primary h5 fw-normal mb-0"></i>
                </span>
                <input
                  name="name"
                  id="location"
                  type="text"
                  className="form-control border-0"
                  placeholder="Location:"
                  value={postalCode}
                  contentEditable={true}
                  onChange={(e) => {
                    setPostalCode(e.target.value);
                  }}
                />
              </div>
            </div>
            <div className="col-md-auto mt-4 mt-sm-0">
              <div className="d-grid d-md-block">
                <button
                  type="button"
                  onClick={searchPharmacies}
                  className="btn btn-primary"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      {showPharmacies ? <Pharmacies {...props} /> : null}
    </div>
  );
}

const Pharmacies = (data) => {
  // Click Event
  const handleClick = (pharm) => (event) => {
    // Get Data
    let practitioner;
    getPractitionerByOds(pharm.organizationId, pharm.odsCode).then(
      (response) => {
        practitioner = response.data;
        // Format Data
        let selectionData = {
          serviceName: data.metadata.serviceName,
          serviceId: data.metadata.serviceId,
          isNhs: data.metadata.isNhs,
          practitionerDataMongo: pharm,
          practitionerData: practitioner,
        };
        // Send to Parent
        data.handleSelection(selectionData);
      },
    );
  };
  return (
    <div className="container">
      <div className="row align-items-center">
        {data.pharmacies.map((item, index) => {
          return (
            <div
              className="col-lg-4 col-md-8 mt-4 pt-2 ml-lg-0 px-1"
              key={index}
            >
              <HashLink
                to="/book/services/gp#appointment"
                className="title text-dark h5 d-block mt-0 mb-0"
                onClick={handleClick(item)}
              >
                <div
                  className="card team border-0 rounded shadow overflow-hidden"
                  style={{
                    opacity: 0.7,
                    height: 150,
                    fontFamily: "sans-serif",
                    font: "message-box",
                  }}
                >
                  <div className="row align-items-center">
                    <div className="card-body">
                      {item.practitioner}
                      <div className="d-flex justify-content-center align-items-center mt-0 mb-lg-0">
                        <Rating
                          name="read-only"
                          value={item.ratings.toString()}
                          readOnly
                        />
                      </div>
                      <ul className="list-unstyled mt-lg-0 pt-lg-0 mb-0">
                        <li className="d-flex ms-0 mb-0 ms-0">
                          <i className="ri-user-location-fill text-primary fw-normal mb-0">
                            <small
                              className="ms-2"
                              style={{
                                color: "#000000",
                                fontFamily: "sans-serif",
                                fontWeight: "normal",
                              }}
                            >
                              {`${capitalizeFirstLetter(
                                item.addressLine1.trim(),
                              )} ${
                                item.addressLine2
                                  ? `${capitalizeFirstLetter(
                                      item.addressLine2.trim(),
                                    )},`
                                  : ","
                              } ${capitalizeFirstLetter(
                                item.cityNm.trim(),
                              )}, ${item.countryNm.toUpperCase().trim()}`}
                            </small>
                          </i>
                        </li>
                        <li className="d-flex mt-0 mb-0 ms-0">
                          <i className="ri-phone-fill text-primary fw-normal mb-0">
                            <small
                              className="ms-2"
                              style={{
                                color: "#000000",
                                fontFamily: "sans-serif",
                                fontWeight: "normal",
                              }}
                            >
                              {item.phoneNum}
                            </small>
                          </i>
                        </li>
                        <li className="d-flex mt-0 mb-0 ms-0">
                          <i className="ri-mail-add-fill text-primary fw-normal mb-0">
                            <small
                              className="ms-2"
                              style={{
                                color: "#000000",
                                fontFamily: "sans-serif",
                                fontWeight: "normal",
                              }}
                            >
                              {item.emailAddress
                                ? item.emailAddress.toLowerCase()
                                : "contact@ziplanerx.com"}
                            </small>
                          </i>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </HashLink>
            </div>
          );
        })}
      </div>
    </div>
  );
};

function capitalizeFirstLetter(string) {
  return string
    .toLowerCase()
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export default GPSearchFields;
