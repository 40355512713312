// our mission, vision, goal
import React from "react";

const AboutUsMini = () => {
  return (
    <section className="section py-5 bg-light">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div className="d-flex">
              <i className="uil uil-briefcase h1 mb-0 text-primary"></i>
              <div className="ms-3 ms-lg-4">
                <h5>Our Mission</h5>
                <p className="text-muted mb-0">
                  Revolutionalize digital healthcare by fostering inclusivity,
                  accessibility and empowerment for customers and healthcare
                  practitioners.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="d-flex">
              <i className="uil uil-airplay h1 mb-0 text-primary"></i>
              <div className="ms-3 ms-lg-4">
                <h5>Our Vision</h5>
                <p className="text-muted mb-0">
                  Bring sophisticated digital innovations that brings simplicity
                  in accessing healthcare options for customers, and meaningful
                  growth to our practitioners.
                </p>
              </div>
            </div>
          </div>

          <div className="col-md-4 mt-4 pt-2 mt-sm-0 pt-sm-0">
            <div className="d-flex">
              <i className="uil uil-flip-v h1 mb-0 text-primary"></i>
              <div className="ms-3 ms-lg-4">
                <h5>Who We Are ?</h5>
                <p className="text-muted mb-0">
                  Health-tech, where wellness is re-defined!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUsMini;
