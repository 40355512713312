// offcanvas-searchbar
import React, { Component } from "react";

class OffcanvasSearchbar extends Component {
  render() {
    return (
      <div className="offcanvas offcanvas-top" tabIndex="-1" id="offcanvasTop">
        <div className="offcanvas-body d-flex align-items-center align-items-center">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="text-center">
                  <h4>Search now.....</h4>
                  <div className="subcribe-form mt-4">
                    <form>
                      <div className="mb-0">
                        <input
                          type="text"
                          id="help"
                          name="name"
                          className="border rounded-pill"
                          required=""
                          placeholder="Search"
                        />
                        <button
                          type="submit"
                          className="btn btn-pills btn-primary"
                        >
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default OffcanvasSearchbar;
