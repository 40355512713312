import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { FiHome, SlSocialGoogle } from "../../assets/icons/vander";
import { FaSquareFacebook } from "react-icons/fa6";
import { emailValidator } from "../../utils/validators";
import { withAuth0 } from "@auth0/auth0-react";
import AuthService from "../../utils/auth/auth.service";
import { FaArrowAltCircleRight } from "react-icons/fa";

const PatientLogin = () => {
  // Variables
  const auth = new AuthService();
  const [isSignUp, setIsSignUp] = useState(false);
  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [emailAddress, setEmailAddress] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState(null);
  const [emailValidation, setEmailValidation] = useState({
    valid: true,
    message: "Email Is Required",
  });
  const [passwordValidation, setPasswordValidation] = useState({
    valid: true,
    message: "Password Is Required",
  });
  const [firstNameValidation, setFirstNameValidation] = useState({
    valid: true,
    message: "First Name Is Required",
  });
  const [lastNameValidation, setLastNameValidation] = useState({
    valid: true,
    message: "Last Name Is Required",
  });

  // Validations
  const firstNameChange = (e) => {
    setFirstName(e.target.value);
    if (!e.target.value) {
      setFirstNameValidation({
        valid: false,
        message: "First Name Is Required",
      });
    } else {
      setFirstNameValidation({ valid: true, message: "" });
    }
  };

  const lastNameChange = (e) => {
    setLastName(e.target.value);
    if (!e.target.value) {
      setLastNameValidation({
        valid: false,
        message: "Last Name Is Required",
      });
    } else {
      setLastNameValidation({ valid: true, message: "" });
    }
  };

  const emailAddressChange = (e) => {
    setEmailAddress(e.target.value);
    if (emailValidator(e.target.value)) {
      setEmailValidation({ valid: true, message: "" });
    } else {
      setEmailValidation({
        valid: false,
        message: "Please Enter Valid Email Address",
      });
    }
  };

  const passwordChange = (e) => {
    setPassword(e.target.value);
    if (!e.target.value) {
      setPasswordValidation({
        valid: false,
        message: "Password Is Required",
      });
    } else {
      setPasswordValidation({ valid: true, message: "" });
    }
  };

  const handleLogin = () => {
    auth
      .login({
        username: emailAddress,
        password: password,
        realm: "Username-Password-Authentication",
      })
      .then()
      .catch((err) => {
        setError(err);
      });
  };

  const handleSignup = () => {
    auth
      .signup({
        given_name: firstName,
        family_name: lastName,
        name: `${firstName} ${lastName}`,
        email: emailAddress,
        username: emailAddress,
        password: password,
        nickname: firstName,
        connection: "Username-Password-Authentication",
      })
      .then(() => {
        setIsSignUp(false);
      })
      .catch((err) => {
        setError(err);
      });
  };

  return (
    <Fragment>
      <div className="back-to-home rounded d-none d-sm-block">
        <Link to="/" className="btn btn-icon btn-primary">
          <FiHome className="icons" />
        </Link>
      </div>

      <section
        className="bg-home d-flex bg-light align-items-center"
        style={{
          backgroundImage: "#202942",
          backgroundPosition: "center",
        }}
      >
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-5 col-md-8">
              {isSignUp ? (
                <div className="card login-page shadow mt-4 rounded border-0">
                  <div
                    className="card-body text-primary"
                    style={{ backgroundColor: "#ffffff" }}
                  >
                    <div className="">
                      <Link className="logo" to="/home">
                        <span className="logo-light-mode">
                          <img
                            src={"assets/images/logo-icon.png"}
                            className="l-dark"
                            height="100"
                            alt=""
                          />
                        </span>
                      </Link>
                    </div>
                    <h4 className="text-center">Sign Up</h4>
                    <form className="login-form mt-4">
                      <div className="row">
                        {/*Names*/}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            {/*<label className="form-label">*/}
                            {/*  First Name <span className="text-danger">*</span>*/}
                            {/*</label>*/}
                            <input
                              name="firstName"
                              id="firatName"
                              type="text"
                              className="form-control"
                              placeholder="First Name*"
                              value={firstName}
                              onChange={firstNameChange}
                            />
                            {!firstNameValidation.valid ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {firstNameValidation.message}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="mb-3">
                            {/*<label className="form-label">*/}
                            {/*  Last Name <span className="text-danger">*</span>*/}
                            {/*</label>*/}
                            <input
                              name="lastName"
                              id="lastName"
                              type="text"
                              className="form-control"
                              placeholder="Last Name*"
                              value={lastName}
                              onChange={lastNameChange}
                            />
                            {!firstNameValidation.valid ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {lastNameValidation.message}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {/*Email*/}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            {/*<label className="form-label">*/}
                            {/*  Your Email <span className="text-danger">*</span>*/}
                            {/*</label>*/}
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder="Your email :*"
                              value={emailAddress}
                              onChange={emailAddressChange}
                            />
                            {!emailValidation.valid ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {emailValidation.message}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {/*Password*/}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            {/*<label className="form-label">*/}
                            {/*  Password <span className="text-danger">*</span>*/}
                            {/*</label>*/}
                            <input
                              name="name"
                              id="name"
                              type="password"
                              className="form-control"
                              placeholder="Password :*"
                              value={password}
                              onChange={passwordChange}
                            />
                            {!passwordValidation.valid ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {passwordValidation.message}{" "}
                              </span>
                            ) : null}
                            {error ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {error.description}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {/*Buttons*/}
                        <div className="col-lg-12 mb-0">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={handleSignup}
                            >
                              Sign Up
                            </button>
                          </div>
                        </div>
                        {/*Or*/}
                        {/*<div className="col-lg-12 mt-3 text-center">*/}
                        {/*  <h6 className="text-muted">Or</h6>*/}
                        {/*</div>*/}
                        {/*/!*Social*!/*/}
                        {/*<div className="col-6 mt-3">*/}
                        {/*  <div className="d-grid">*/}
                        {/*    <Link to="#" className="btn btn-soft-primary">*/}
                        {/*      <FaArrowAltCircleRight className="mb-0" /> NHS*/}
                        {/*    </Link>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 mt-3">*/}
                        {/*  <div className="d-grid">*/}
                        {/*    <Link to="#" className="btn btn-soft-primary">*/}
                        {/*      <SlSocialGoogle className="mb-0" /> Google*/}
                        {/*    </Link>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {/*Alternate*/}
                        <div className="col-12 text-center">
                          <p className="mb-0 mt-3">
                            <h6 className="text-dark me-2">
                              Have an account ?
                            </h6>{" "}
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => setIsSignUp(false)}
                            >
                              Sign In
                            </button>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              ) : (
                <div className="card login-page shadow mt-4 rounded border-0">
                  <div
                    className="card-body text-primary"
                    style={{ backgroundColor: "#ffffff" }}
                  >
                    <div className="">
                      <Link className="logo" to="/home">
                        <span className="logo-light-mode">
                          <img
                            src={"assets/images/logo-icon.png"}
                            className="l-dark"
                            height="100"
                            alt=""
                          />
                        </span>
                      </Link>
                    </div>
                    <h4 className="text-center">Sign In</h4>
                    <form className="login-form mt-4">
                      <div className="row">
                        {/*Email*/}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Your Email <span className="text-danger">*</span>
                            </label>
                            <input
                              name="email"
                              id="email"
                              type="email"
                              className="form-control"
                              placeholder="Your email :"
                              value={emailAddress}
                              onChange={emailAddressChange}
                            />
                            {!emailValidation.valid ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {emailValidation.message}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {/*Password*/}
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <label className="form-label">
                              Password <span className="text-danger">*</span>
                            </label>
                            <input
                              name="name"
                              id="name"
                              type="password"
                              className="form-control"
                              placeholder="Password :"
                              value={password}
                              onChange={passwordChange}
                            />
                            {!passwordValidation.valid ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {passwordValidation.message}{" "}
                              </span>
                            ) : null}
                            {error ? (
                              <span
                                className="text-danger"
                                style={{ fontSize: "smaller" }}
                              >
                                {" "}
                                {error.description}{" "}
                              </span>
                            ) : null}
                          </div>
                        </div>
                        {/*Buttons*/}
                        <div className="col-lg-12">
                          <div className="d-flex justify-content-between">
                            <Link
                              to="/forgot-password"
                              className="text-dark h6 mb-0"
                            >
                              Forgot password ?
                            </Link>
                          </div>
                        </div>
                        <div className="col-lg-12 mb-0">
                          <div className="d-grid">
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={handleLogin}
                            >
                              Sign in
                            </button>
                          </div>
                        </div>
                        {/*Social*/}
                        {/*<div className="col-lg-12 mt-3 text-center">*/}
                        {/*  <h6 className="text-muted">Or</h6>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 mt-3">*/}
                        {/*  <div className="d-grid">*/}
                        {/*    <Link to="#" className="btn btn-soft-primary">*/}
                        {/*      <FaArrowAltCircleRight className="mb-0" /> NHS*/}
                        {/*    </Link>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {/*<div className="col-6 mt-3">*/}
                        {/*  <div className="d-grid">*/}
                        {/*    <Link to="#" className="btn btn-soft-primary">*/}
                        {/*      <SlSocialGoogle className="mb-0" /> Google*/}
                        {/*    </Link>*/}
                        {/*  </div>*/}
                        {/*</div>*/}
                        {/*Alternate*/}
                        <div className="col-12 text-center">
                          <p className="mb-0 mt-3">
                            <h6 className="text-dark me-2">
                              Don't have an account ?
                            </h6>{" "}
                            <button
                              className="btn btn-primary"
                              type="button"
                              onClick={() => setIsSignUp(true)}
                            >
                              Sign Up
                            </button>
                          </p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default withAuth0(PatientLogin);
