import React, { Fragment, useEffect, useState } from "react";
import { useStripeConnect } from "./hooks/useStripeConnect";
import { createAccount } from "../../utils/stripe.service";
import {
  ConnectAccountOnboarding,
  ConnectComponentsProvider,
} from "@stripe/react-connect-js";
import { useLocation } from "react-router-dom";

export default function StripeOnboarding() {
  const [accountCreatePending, setAccountCreatePending] = useState(false);
  const [onboardingExited, setOnboardingExited] = useState(false);
  const [error, setError] = useState(false);
  const [connectedAccountId, setConnectedAccountId] = useState();
  const stripeConnectInstance = useStripeConnect(connectedAccountId);
  const location = useLocation();

  // Extract query parameters
  useEffect(() => {
    // Extract query parameters
    const searchParams = new URLSearchParams(location.search);
    const accountId = searchParams.get("account_id");
    if (accountId) {
      setConnectedAccountId(accountId);
    } else {
      window.open(process.env.REACT_APP_frontoffice_base, "_self");
    }
  }, [location]);

  // Helpers
  const handleCreateAccount = async () => {
    // Set States
    setAccountCreatePending(true);
    setError(false);

    // Make Call
    createAccount({})
      .then((response) => {
        setAccountCreatePending(false);
        const { id, account, error } = response.data;

        if (id) {
          setConnectedAccountId(id);
        } else if (account) {
          setConnectedAccountId(account || account.id);
        }

        if (error) {
          setError(true);
        }
      })
      .catch(() => {
        setError(true);
      });
  };

  return (
    <Fragment>
      <div className="container">
        <div className="banner">
          <h2>ZipLaneRx</h2>
          <h6>
            Revolutionizing digital healthcare by fostering inclusivity,
            accessibility and empowerment for customers and healthcare
            practitioners.
          </h6>
        </div>
        <div className="content">
          {!connectedAccountId && <h5>Connect your account</h5>}
          {connectedAccountId && !stripeConnectInstance && (
            <h2>Add information to start accepting money</h2>
          )}
          {
            !connectedAccountId
            //     &&
            //     (
            //   <p>
            //     Revolutionizing digital healthcare by fostering inclusivity,
            //     accessibility and empowerment for customers and healthcare
            //     practitioners.
            //   </p>
            // )
          }
          {!accountCreatePending && !connectedAccountId && (
            <div>
              <button onClick={async () => handleCreateAccount()}>
                Sign up
              </button>
            </div>
          )}
          {stripeConnectInstance && (
            <ConnectComponentsProvider connectInstance={stripeConnectInstance}>
              <ConnectAccountOnboarding
                onExit={() => setOnboardingExited(true)}
              />
            </ConnectComponentsProvider>
          )}
          {error && <p className="error">Something went wrong!</p>}
          {(connectedAccountId || accountCreatePending || onboardingExited) && (
            <div className="dev-callout">
              {connectedAccountId && (
                <p>
                  Your connected account ID is:{" "}
                  <code className="bold">{connectedAccountId}</code>
                </p>
              )}
              {accountCreatePending && <p>Creating a connected account...</p>}
              {onboardingExited && (
                <p>
                  The Account Onboarding is complete. Please close this window,
                  and return to previous screen.
                </p>
              )}
            </div>
          )}
        </div>
      </div>
    </Fragment>
  );
}
