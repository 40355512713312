import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";
import Header from "../../components/header/header";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import { FiArrowRightCircle } from "react-icons/fi";
import BmrCalculatorForm from "../../components/forms/calculators/bmr-calculator";

export default function Bmr() {
  // Variables
  const [data, setData] = useState(null);
  const [results, setResults] = useState(null);
  const [showResults, setShowResults] = useState(false);

  // Use Effect
  useEffect(() => {
    if (data) {
      // Calculate
      let bmr = calculate(data);
      // Set Results
      setResults(bmr);
      setShowResults(true);
    } else {
      setShowResults(false);
    }
  }, [data]);

  // Handlers
  const handleData = (data) => {
    // Set Data
    setData(data);
  };

  // Calculate BMR
  function calculate(data) {
    let value;
    let total;
    let a = data && data.age ? parseInt(data.age) : 0;
    let goal = data && data.goal ? data.goal : "light";
    let isMale = data && data.isMale ? data.isMale : false;
    if (data.isMetric) {
      // METRIC Calculation
      let h = data && data.height1 ? parseInt(data.height1) : 0;
      let w = data && data.weight1 ? parseInt(data.weight1) : 0;
      // Calculate
      if (isMale) {
        value = 66 + 13.7 * w + 5 * h - 6.7 * a;
      } else {
        value = 655 + 9.5 * w + 1.85 * h - 4.6 * a;
      }
    } else {
      // Imperial Calculation
      let h1 = data && data.height2 ? parseInt(data.height2) * 12 : 0;
      let h2 = data && data.height3 ? parseInt(data.height3) : 0;
      let h = h1 + h2;
      let w = data && data.weight2 ? parseInt(data.weight2) : 0;
      // Calculate
      if (isMale) {
        value = 66 + 13.7 * (w / 2.20462) + 5 * (h * 2.54) - 6.7 * a;
      } else {
        value = 655 + 9.5 * (w / 2.20462) + 1.85 * (h * 2.54) - 4.6 * a;
      }
    }

    value = Math.round(value * 10) / 10;

    // Goal Calculation
    if (goal === "light") {
      total = Math.round(value * 10 * 1.4) / 10;
    } else if (goal === "moderate") {
      total = Math.round(value * 10 * 1.6) / 10;
    } else if (goal === "high") {
      total = Math.round(value * 10 * 1.8) / 10;
    } else if (goal === "extreme") {
      total = Math.round(value * 10 * 2) / 10;
    }
    return { value, total };
  }

  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/healthyliving/metabolic.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          height: 400,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <BmrCalculatorForm handleData={handleData} />
      <section
        className="section"
        style={{
          marginBottom: 0,
          paddingBottom: 0,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        {showResults ? (
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-lg-9">
                <div className="card border-0 shadow rounded overflow-hidden">
                  <h4>
                    Base Metabolic Rate (BMR):{" "}
                    <span className="text-danger">{results.value}</span>{" "}
                    calories / day
                  </h4>
                  <h4>
                    Total Daily Energy Expenditure (TDEE):{" "}
                    <span className="text-danger">{results.total}</span>{" "}
                    calories / day
                  </h4>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </section>

      <section
        className="section"
        style={{
          marginBottom: 5,
          paddingBottom: 5,
          marginTop: 5,
          paddingTop: 5,
        }}
      >
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">BMR</h3>
                  <h5 className="card-title">What is BMR?</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      The Basal Metabolic Rate (BMR) Calculator estimates your
                      basal metabolic rate—the amount of energy expended while
                      at rest in a neutrally temperate environment, and in a
                      post-absorptive state (meaning that the digestive system
                      is inactive, which requires about 12 hours of fasting).
                    </p>
                  </ul>

                  <h5 className="card-title">BMR introduction:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      The basal metabolic rate (BMR) is the amount of energy
                      needed while resting in a temperate environment when the
                      digestive system is inactive. It is the equivalent of
                      figuring out how much gas an idle car consumes while
                      parked. In such a state, energy will be used only to
                      maintain vital organs, which include the heart, brain,
                      kidneys, nervous system, intestines, liver, lungs, sex
                      organs, muscles, and skin. For most people, upwards of
                      ~70% of total energy (calories) burned each day is due to
                      upkeep. Physical activity makes up ~20% of expenditure and
                      ~10% is used for the digestion of food, also known as
                      thermogenesis.
                    </p>
                    <p>
                      The BMR is measured under very restrictive circumstances
                      while awake. An accurate BMR measurement requires that a
                      person's sympathetic nervous system is inactive, which
                      means the person must be completely rested. Basal
                      metabolism is usually the largest component of a person's
                      total caloric needs. The daily caloric need is the BMR
                      value multiplied by a factor with a value between 1.2 and
                      1.9, depending on activity level.
                    </p>
                    <p>
                      In most situations, the BMR is estimated with equations
                      summarized from statistical data. The Harris-Benedict
                      Equation was one of the earliest equations introduced. It
                      was revised in 1984 to be more accurate and was used up
                      until 1990, when the Mifflin-St Jeor Equation was
                      introduced. The Mifflin-St Jeor Equation has been shown to
                      be more accurate than the revised Harris-Benedict
                      Equation. The Katch-McArdle Formula is slightly different
                      in that it calculates resting daily energy expenditure
                      (RDEE), which takes lean body mass into account, something
                      that neither the Mifflin-St Jeor nor the Harris-Benedict
                      Equation does. Of these equations, the Mifflin-St Jeor
                      Equation is considered the most accurate equation for
                      calculating BMR with the exception that the Katch-McArdle
                      Formula can be more accurate for people who are leaner and
                      know their body fat percentage. You can pick the equation
                      to be used in the calculation by expanding the settings.
                    </p>
                  </ul>

                  <h5 className="card-title">Mifflin-St Jeor Equation:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        For men: BMR = 10W + 6.25H - 5A + 5
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        For women: BMR = 10W + 6.25H - 5A - 161
                      </li>
                    </p>
                  </ul>

                  <h5 className="card-title">
                    Revised Harris-Benedict Equation:
                  </h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        For men: BMR = 13.397W + 4.799H - 5.677A + 88.362
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        For women: BMR = 9.247W + 3.098H - 4.330A + 447.593
                      </li>
                    </p>
                  </ul>

                  <h5 className="card-title">RKatch-McArdle Formula:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        BMR = 370 + 21.6(1 - F)W
                      </li>
                      Where: W is body weight in kg H is body height in cm A is
                      age F is body fat in percentage
                    </p>
                  </ul>

                  <h5 className="card-title">BMR Variables</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Muscle Mass</span>: Aerobic
                        exercises, such as running or cycling, have no effect on
                        BMR. However, anaerobic exercises, such as
                        weight-lifting, indirectly lead to a higher BMR because
                        they build muscle mass, increasing resting energy
                        consumption. The more muscle mass in the physical
                        composition of an individual, the higher the BMR
                        required to sustain their body at a certain level.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Age</span>: The more elderly
                        and limber an individual, the lower their BMR, or the
                        lower the minimum caloric intake required to sustain the
                        functioning of their organs at a certain level.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Genetics</span>: Hereditary
                        traits passed down from ancestors influence BMR.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Weather</span>: Cold
                        environments raise BMR because of the energy required to
                        create a homeostatic body temperature. Likewise, too
                        much external heat can raise BMR as the body expends
                        energy to cool off internal organs. BMR increases
                        approximately 7% for every increase of 1.36 degrees
                        Fahrenheit in the body's internal temperature.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Diet</span>: Small, routinely
                        dispersed meals increase BMR. On the other hand,
                        starvation can reduce BMR by as much as 30%. Similar to
                        a phone that goes into power-saving mode during the last
                        5% of its battery, a human body will make sacrifices
                        such as energy levels, moods, upkeep of bodily physique,
                        and brain functions in order to more efficiently utilize
                        what little caloric energy is being used to sustain it.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Pregnancy</span>: Ensuring the
                        livelihood of a separate fetus internally increases BMR.
                        This is why pregnant women tend to eat more than usual.
                        Also, menopause can increase or decrease BMR depending
                        on hormonal changes.
                      </li>
                      <li>
                        <FiArrowRightCircle className="fea icon-sm me-2 mb-0" />
                        <span className="fw-bold">Supplements</span>: Certain
                        supplements or drugs raise BMR, mostly to fuel weight
                        loss. Caffeine is a common one.
                      </li>
                    </p>
                  </ul>

                  <h5 className="card-title">BMR Tests:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      Online BMR tests with rigid formulas are not the most
                      accurate method of determining an individual's BMR. It is
                      better to consult a certified specialist or measure BMR
                      through a calorimetry device. These handheld devices are
                      available in many health and fitness clubs, doctor
                      offices, and weight-loss clinics.
                    </p>
                  </ul>

                  <h5 className="card-title">Resting Metabolic Rate:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      While the two are used interchangeably, there is a key
                      difference in their definitions. Resting metabolic rate,
                      or RMR for short, is the rate at which the body burns
                      energy in a relaxed, but not fully inactive state. It is
                      also sometimes defined as resting energy expenditure, or
                      REE. BMR measurements must meet total physiological
                      equilibrium while RMR conditions of measurement can be
                      altered and defined by contextual limitations.
                    </p>
                  </ul>

                  <h5 className="card-title">Modern Wisdom:</h5>
                  <ul className="list-unstyled text-muted">
                    <p>
                      A 2005 meta-analysis study on BMR* showed that when
                      controlling all factors of metabolic rate, there is still
                      a 26% unknown variance between people. Essentially, an
                      average person eating an average diet will likely have
                      expected BMR values, but there are factors that are still
                      not understood that determines BMR precisely.
                    </p>
                    <p>
                      Therefore, all BMR calculations, even using the most
                      precise methods through specialists, will not be perfectly
                      accurate in their measurements. Not all human bodily
                      functions are well understood just yet, so calculating
                      total daily energy expenditure (TDEE) derived from BMR
                      estimates are just that, estimates. When working towards
                      any sort of health or fitness goal, BMR can aid in laying
                      down the foundations, but from there on, it has little
                      else to offer. A calculated BMR and thus TDEE may result
                      in unsatisfactory results because of their rough
                      estimates, but maintaining a daily journal of exercise,
                      food consumption, etc., can help track the factors that
                      lead to any given results and help determine what works,
                      as well as what needs to be improved upon. Tracking
                      progress in said journal and making adjustments over time
                      as needed is generally the best indication of progress
                      towards reaching personal goals.
                    </p>
                  </ul>

                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
