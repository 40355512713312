import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";

import { FiArrowRight } from "react-icons/fi";
import Header from "../../components/header/header";
import AboutUsMini from "../../components/aboutus-mini/aboutus-mini";
import { MdOutlineLocalPrintshop } from "react-icons/md";

export default function AboutUs() {
  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/heart.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center center",
          overflow: "scroll",
          // width: 500,
          height: 600,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="section-title text-center">
              <nav aria-label="breadcrumb" className="d-inline-block mt-3 mb-3">
                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                  <li className="breadcrumb-item">
                    <Link to="/">ZipLaneRx</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    About us
                  </li>
                </ul>
              </nav>
              <AboutUsMini />
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">About Us:</h3>
                  <p className="text-muted">
                    Welcome to ZipLaneRx, where our mission is to revolutionize
                    healthcare by fostering inclusivity, accessibility and
                    empowerment for both customers and healthcare practitioners.
                    We believe that everyone deserves quality and affordable
                    healthcare regardless of the background or location.
                  </p>
                  <p className="text-muted">
                    Our platform is dedicated to breaking barriers, providing a
                    space where individuals can easily connect with diverse
                    practitioners, access a range of healthcare products and
                    services, and avail those through telemedicine as well as
                    in-person experiences, contributing to a more democratized
                    healthcare experience.
                  </p>
                  <p className="text-muted">
                    Join us on this journey toward a healthier, more inclusive
                    future for all!
                  </p>

                  <h5 className="card-title">What we offer:</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      For our customers, we offer convenience, accessibility and
                      effectiveness in healthcare products and services.
                    </p>
                    <p className="text-muted">
                      Convenience: Order and book appointments for a range of
                      NHS and Private products and services from Pharmacists,
                      Pathologists and GP’s, all through the comfort of your
                      phone, tablet or a computer.{" "}
                      <span className="text-primary">
                        It’s as simple as Click, Pay and Order or Book!
                      </span>
                    </p>
                    <p className="text-muted">Accessibility:</p>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      We recognize that healthcare needs are non-cyclical. Our
                      platform is always available - 24/7 and 365 days every
                      year to take and process your needs when they arise.{" "}
                      <span className="text-primary">
                        No waiting, no anxiety!{" "}
                      </span>
                    </li>
                    <li className="mt-2 ms-0">
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Orders can be home delivered or picked in-person; services
                      can be rendered digitally through a video or voice
                      consultation and in-person visitations!{" "}
                      <span className="text-primary">
                        Accessibility must be stress free!{" "}
                      </span>
                    </li>
                  </ul>
                  <p className="text-muted">
                    Effective: We bring you a curated marketplace of products
                    and certified healthcare service providers. Pick and choose
                    from the best-rated to the most cost-effective provider.
                    <span className="text-primary">
                      Everyone deserves quality and affordable healthcare!
                    </span>
                  </p>
                  <p className="text-muted">
                    For our certified pharmacist, pathologist and general
                    practitioners, we offer a versatile platform to help you
                    learn, lean and grow your business digitally.
                  </p>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Hyper Digitization that{" "}
                      <span className="text-primary">
                        drives broader outreach
                      </span>
                      .
                    </p>
                    <li className="mt-2 ms-0">
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />A
                      completely digitized practitioner onboarding, booking,
                      ordering and invoicing experience.
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Expand your product and service outreach through
                      integrated telemedicine options and order shipping.
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Enhance your brand through highly effective marketing on
                      us!
                    </li>
                  </ul>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Intelligent Automation that{" "}
                      <span className="text-primary">
                        improves your operational expense posture
                      </span>
                      .
                    </p>
                    <li className="mt-2 ms-0">
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />{" "}
                      Intuitive self-service Account Management.
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Integrated self-service ordering and service management
                      system.
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Frictionless calendarized order and service booking for
                      the customers and practitioners alike.
                    </li>
                  </ul>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Curated Insights to help you{" "}
                      <span className="text-primary">
                        make sound and profitable decisions
                      </span>
                      .
                    </p>
                    <li className="mt-2 ms-0">
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Improve customer satisfaction to attract more customers,
                      through rich customer insights.
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Curated recommendations on competitive pricing, Pricing
                      elasticity, seasonal products and services.
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Rich insights into your business trends and the
                      profitability factors for your considerations.
                    </li>
                  </ul>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Secured by Design so you can{" "}
                      <span className="text-primary">
                        focus on expanding your business
                      </span>
                      .
                    </p>
                    <li className="mt-2 ms-0">
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Industry standard oAuth 2.0 authentication protocol.
                    </li>
                    {/*TODO: We will comeback to this*/}
                    <li className="ms-0">
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Multi-factor authentication (Coming Soon).
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      GDPR and Data Protection Act 2018 compliant.
                    </li>
                    <li>
                      <FiArrowRight className="fea icon-sm me-2 mb-0" />
                      Practitioners are MHRA and GPhC regulated.
                    </li>
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
