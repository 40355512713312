import React from "react";
import { Link } from "react-router-dom";

const Pagination = ({ nPages, currentPage, setCurrentPage, path }) => {
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  return (
    <nav>
      <ul className="pagination justify-content-center mt-5">
        {/*<li className="page-item">*/}
        {/*  <a className="page-link" onClick={goToPrevPage} href="#">*/}
        {/*    Previous*/}
        {/*  </a>*/}
        {/*</li>*/}
        {pageNumbers.map((pgNumber) => (
          <li
            key={pgNumber}
            className={`page-item ${currentPage == pgNumber ? "active" : ""} `}
          >
            <Link
              onClick={() => setCurrentPage(pgNumber)}
              className="page-link"
              to={path}
            >
              {pgNumber}
            </Link>
          </li>
        ))}
        {/*<li className="page-item">*/}
        {/*  <a className="page-link" onClick={goToNextPage}>*/}
        {/*    Next*/}
        {/*  </a>*/}
        {/*</li>*/}
      </ul>
    </nav>
  );
};

export default Pagination;
