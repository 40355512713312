import { saveAs } from "file-saver";

export function createPdfBlobUrl(consentForm) {
  let arrBuffer = arrayToArrayBuffer(consentForm.document.data);
  const blob = new Blob([arrBuffer], { type: "application/pdf" });
  saveAs(blob, consentForm.templateName);
}

export function arrayToArrayBuffer(array) {
  const uint8Array = new Uint8Array(array);
  return uint8Array.buffer;
}
