import React, { Fragment, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

export default function StripeCheckoutExit() {
  const [connectedSessionId, setConnectedSessionId] = useState();
  const location = useLocation();

  // Extract query parameters
  useEffect(() => {
    // Extract query parameters
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    if (sessionId) {
      setConnectedSessionId(sessionId);
    } else {
      window.open(process.env.REACT_APP_frontoffice_base, "_self");
    }
  }, [location]);

  // Helpers

  return (
    <Fragment>
      <div className="container">
        <div className="banner">
          <h2>ZipLaneRx</h2>
          <h6>
            Revolutionizing digital healthcare by fostering inclusivity,
            accessibility and empowerment for customers and healthcare
            practitioners.
          </h6>
        </div>
        <div className="content">
          <div className="dev-callout">
            <p>
              The Subscription Checkout is now complete. Please close this
              window, and return to previous screen.
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
