export const bmiRange = [
  {
    classification: "Severe Thinness",
    range: "< 16",
  },
  {
    classification: "Moderate Thinness",
    range: "16 - 17",
  },
  {
    classification: "Mild Thinness",
    range: "17 - 18.5",
  },
  {
    classification: "Normal",
    range: "18.5 - 25",
  },
  {
    classification: "Overweight",
    range: "25 - 30",
  },
  {
    classification: "Obese Class I",
    range: "30 - 35",
  },
  {
    classification: "Obese Class II",
    range: "35 - 40",
  },
  {
    classification: "Obese Class III",
    range: "> 40",
  },
];

export const rdaProtein = [
  {
    group: "Age 1 - 3",
    value: "13",
  },
  {
    group: "Age 4 - 8",
    value: "19",
  },
  {
    group: "Age 9 - 13",
    value: "34",
  },
  {
    group: "Age 14 - 18 (Girls)",
    value: "46",
  },
  {
    group: "Age 14 - 18 (Boys)",
    value: "52",
  },
  {
    group: "Age 19 - 70+ (Women)",
    value: "46",
  },
  {
    group: "Age 19 - 70+ (Men)",
    value: "56",
  },
];

export const proteinReqPregnancy = [
  {
    group: "Pregnancy trimester 1",
    safe: "1",
    additional: "375",
    ratio: "0.04",
  },
  {
    group: "Pregnancy trimester 2",
    safe: "10",
    additional: "1,200",
    ratio: "0.11",
  },
  {
    group: "Pregnancy trimester 3",
    safe: "31",
    additional: "1,950",
    ratio: "0.23",
  },
  {
    group: "Lactation First 6 months",
    safe: "19",
    additional: "2,800",
    ratio: "0.11",
  },
  {
    group: "Lactation After 6 months",
    safe: "13",
    additional: "1,925",
    ratio: "0.11",
  },
];

export const proteinAmountFood = [
  {
    name: "Milk (1 cup/8 oz)",
    amount: "8 g",
  },
  {
    name: "Egg (1 large/50 g)",
    amount: "6 g",
  },
  {
    name: "Meat (1 slice / 2 oz)",
    amount: "14 g",
  },
  {
    name: "Seafood (2 oz)",
    amount: "16 g",
  },
  {
    name: "Bread (1 slice/64 g)",
    amount: "8 g",
  },
  {
    name: "Corn (1 cup/166 g)",
    amount: "16 g",
  },
  {
    name: "Rice (1 cup/195 g)",
    amount: "5 g",
  },
  {
    name: "Dry Bean (1 cup/92 g)",
    amount: "16 g",
  },
  {
    name: "Nuts (1 cup/92 g)",
    amount: "20 g",
  },
  {
    name: "Fruits and Vegetables (1 cup)",
    amount: "0 - 1 g",
  },
  {
    name: "Pizza (1 slice/107 g)",
    amount: "12 g",
  },
  {
    name: "Hamburger (McDonald Medium)",
    amount: "20 g",
  },
];

export const partialProteinList = [
  "Almonds",
  "Oats",
  "Broccoli",
  "Lentils",
  "Ezekiel bread",
  "Chia seeds",
  "Pumpkin seeds",
  "Peanuts",
  "Brussels sprouts",
  "Grapefruit",
  "Green peas",
  "Avocados",
  "Mushrooms",
];

export const veganProteinList = [
  "Buckwheat",
  "Hummus and pita",
  "Soy products (tofu, tempeh, edamame beans)",
  "Peanut butter on toast or some other bread",
  "Beans and rice",
  "Quinoa",
  "Hemp and chia seeds",
  "Spirulina",
];

export const meatDairyProteinList = [
  "Eggs",
  "Chicken breast",
  "Cottage cheese",
  "Greek yogurt",
  "Milk",
  "Lean beef",
  "Tuna",
  "Turkey breast",
  "Fish",
  "Shrimp",
];

export const proteinGoalChosen = [
  { value: "Build Muscle", key: "build" },
  { value: "Improve Endurance", key: "improve" },
  { value: "Balanced Nutrition", key: "balanced" },
];

export const carbsGoalChosen = [
  { value: "Light (exercise 1-3 times a week)", key: "light" },
  { value: "Moderate (exercise 3-5 times a week)", key: "moderate" },
  { value: "High (heavy exercise 6-7 times a week)", key: "high" },
  { value: "Extreme (very heavy exercise or physical job)", key: "extreme" },
];
