export function orderDataPayload(data) {
  // Return Payload
  return {
    organisationId: data.practitionerData.organization_id,
    organisationCode: data.practitionerData.parent_ods_nbr,
    organisationType: "Pharmacy",
    subType: "Community Pharmacy",
    organisationStatus: "Visible",
    isPimsManaged: data.practitionerData.pims_managed_in,
    isEPSEnabled: data.practitionerData.eps_managed_in,
    organisationName: data.practitionerData.business_nm,
    address1: data.practitionerData.p_addr1,
    address2: data.practitionerData.p_addr2,
    address3: data.practitionerData.p_addr3,
    city: data.practitionerData.p_city,
    county: data.practitionerData.p_country,
    postCode: data.practitionerData.p_zip,
    latitude: data.practitionerData.business_lat,
    longitude: data.practitionerData.business_long,
    parentODSCode: data.practitionerData.parent_ods_nbr,
    parentName: data.practitionerData.parent_business_nm,
    phone: data.practitionerData.business_phone_nbr,
    email: data.practitionerData.business_email_addr,
    website: data.practitionerData.business_website,
    fax: "",
    organisationImagePath: "",
    googleReviews: data.practitionerDataMongo?.reviews,
    orgProfileName: data.practitionerData.business_nm,
    vatNumber: data.practitionerData.vat_nbr,
    serviceId: data.serviceId,
    serviceName: data.isNhs
      ? `${data.serviceName} (NHS Funded)`
      : data.serviceName,
    serviceSlotTime: data.service.srvc_duration_time,
    serviceCost: `£${data.service.srvc_price_amt}`,
    isChargeable: data.service.chargeable_in,
    currencySymbol: "£",
    serviceDescription: data.isNhs
      ? `${data.service.srvc_nm} (NHS Funded)`
      : data.service.srvc_nm,
    appointmentType: data.serviceMethod,
    orderStatus: "Booked",
    serviceRequestNotes: data.serviceRequestNotes,
    interactiveFormData: data.interactiveFormData, // todo
    fundingType: data.isNhs ? "nhs" : "chargeable",
    deliveryMethod: [data.serviceMethod],
    calendarId: "1a470c8e-40ed-4c2d-b590-a4f1f6ead6cc",
    title: data.isNhs
      ? `${data.service.srvc_nm} (NHS Funded)`
      : data.service.srvc_nm,
    description: data.isNhs
      ? `${data.service.srvc_nm} (NHS Funded)`
      : data.service.srvc_nm,
    start: data.startTime,
    end: data.endTime,
    duration: data.service.srvc_duration_time,
    allDay: false,
    recurrence: null,
    isSlotConfirmed: false,
    firstName: data.firstName,
    lastName: data.lastName,
    emailId: data.emailAddress,
    phoneNumber: data.phoneNumber,
  };
}
