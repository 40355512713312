import axios from "axios";

const baseUrl = process.env.REACT_APP_reference_base;

export async function getAllServices() {
  return axios.get(`${baseUrl}/api/services/all`);
}

export async function getService(srvc_id) {
  return axios.get(`${baseUrl}/api/services/${srvc_id}`);
}

export async function searchServices(data) {
  return axios.post(`${baseUrl}/api/cust/services/search`, data);
}

export async function getConsentForm(templateId) {
  return axios.get(`${baseUrl}/api/services/consent-forms/${templateId}`);
}

export async function getProductCategory(category) {
  return axios.get(`${baseUrl}/api/products/mongo/category/${category}`);
}

export async function getProductById(id) {
  return axios.get(`${baseUrl}/api/products/mongo/productId/${id}`);
}

export async function getProductCategories(criteria) {
  return axios.post(`${baseUrl}/api/products/mongo/get-categories/`, criteria);
}

export async function getProductSubCategories(criteria) {
  return axios.post(
    `${baseUrl}/api/products/mongo/get-sub-categories/`,
    criteria,
  );
}

export async function mongoGetProducts(criteria) {
  criteria["enabled"] = true;
  return axios.post(`${baseUrl}/api/products/mongo/get-products/`, criteria);
}

export async function mongoSearchActiveProducts(criteria) {
  return axios.get(
    `${baseUrl}/api/products/mongo/text-search-active?text=${criteria}`,
  );
}

export async function mongoSearchPharmacies(criteria) {
  return axios.post(`${baseUrl}/api/pharmacy/mongo/query`, criteria);
}
