import auth0 from "auth0-js";
import { createCustomerLogic } from "../usermgmt.service";

export default class PractitionerAuthServiceAuthService {
  auth0 = new auth0.WebAuth({
    domain: `${process.env.REACT_APP_PRACTITIONER_AUTH0_DOMAIN}`,
    clientID: `${process.env.REACT_APP_PRACTITIONER_AUTH0_CLIENTID}`,
    redirectUri: `${process.env.REACT_APP_backoffice_base}/authenticate`,
    audience: `${process.env.REACT_APP_AUTH_AUD}`,
    responseType: "token id_token",
    scope: "openid profile email",
  });

  login(payload) {
    return new Promise((resolve, reject) => {
      this.auth0.login(payload, function (error, response) {
        if (error) {
          reject(error);
        } else if (response) {
          resolve(response);
        }
      });
    });
  }

  signup(payload) {
    return new Promise((resolve, reject) => {
      this.auth0.signup(payload, function (error, response) {
        if (error) {
          reject(error);
        } else if (response) {
          resolve(response);
        }
      });
    });
  }
}
