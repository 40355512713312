import { Link } from "react-router-dom";
import React, { useState } from "react";

const HeartRateCalculatorForm = ({ handleData }) => {
  // Variables
  let [activeIndex, setActiveIndex] = useState(1);
  // NHS Toggle
  const [isMetric, setIsMetric] = useState({
    state: true,
  });
  const [age, setAge] = useState(null);

  // Effects
  // Handlers

  const handleCalculate = () => {
    let values = {
      age,
    };
    handleData(values);
  };

  // Return
  return (
    <section className="section" style={{ marginBottom: 0, paddingBottom: 0 }}>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-9">
            <div className="card border-0 shadow rounded overflow-hidden">
              <ul className="nav nav-pills nav-justified flex-column flex-sm-row rounded-0 shadow overflow-hidden bg-light mb-0">
                <li className="nav-item">
                  <Link
                    className={`${
                      activeIndex === 1 ? "active" : ""
                    } nav-link rounded-0`}
                    onClick={() => setActiveIndex(1)}
                    to="#"
                  >
                    <div className="text-center pt-1 pb-1">
                      <h5 className="fw-medium mb-0">Heart Rate Calculator</h5>
                    </div>
                  </Link>
                </li>
              </ul>

              <div className="tab-content p-4">
                <div className="tab-pane fade show active">
                  <form>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="mb-3">
                          <label className="form-label">
                            Age (Years) <span className="text-danger">*</span>
                          </label>
                          <input
                            name="Age"
                            id="age"
                            type="text"
                            className="form-control"
                            placeholder="Age (Years)"
                            onChange={(e) => setAge(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="d-grid">
                          <button
                            type="button"
                            className="btn btn-primary"
                            onClick={handleCalculate}
                          >
                            Calculate Heart Rate
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeartRateCalculatorForm;
