import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import Footer from "../../components/footer/footer";
import ScrollTop from "../../components/scrollTop";

import { FiArrowRight } from "react-icons/fi";
import Header from "../../components/header/header";
import AboutUsMini from "../../components/aboutus-mini/aboutus-mini";
import { MdOutlineLocalPrintshop } from "react-icons/md";
import {
  supportPortalCookies,
  ziplaneRxCookies,
} from "../../data/cookies-reference";

export default function Cookies() {
  return (
    <Fragment>
      <Header />
      <section
        className="bg-half-170 d-table w-100"
        style={{
          backgroundImage: `url(assets/images/cta.jpg)`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "top center",
          overflow: "scroll",
          // width: 500,
          height: 600,
        }}
      >
        <div className="bg-overlay bg-overlay-dark"></div>
      </section>
      <div className="position-relative">
        <div className="shape overflow-hidden text-color-white">
          <svg
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>
      <div className="container">
        <div className="row mt-5 justify-content-center">
          <div className="col-12">
            <div className="section-title text-center">
              <nav aria-label="breadcrumb" className="d-inline-block mt-3 mb-3">
                <ul className="breadcrumb bg-light rounded mb-0 py-1 px-2">
                  <li className="breadcrumb-item">
                    <Link to="/">ZipLaneRx</Link>
                  </li>
                  <li className="breadcrumb-item active" aria-current="page">
                    Cookies
                  </li>
                </ul>
              </nav>
              <AboutUsMini />
            </div>
          </div>
        </div>
      </div>

      <section className="section">
        <div className="container">
          <div
            className="row justify-content-center"
            style={{ textAlign: "left" }}
          >
            <div className="col-lg-9">
              <div className="card shadow rounded border-0">
                <div className="card-body">
                  <h3 className="card-title">Cookie Consent:</h3>
                  <h5 className="card-title">What are cookies?</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      Cookies are small text files that websites store on your
                      computer to help distinguish you from other users. We use
                      cookies to help us provide you with a better experience
                      when you browse our website and help us to improve our
                      site through monitoring visitor interest and behavior
                      anonymously.
                    </p>
                    <p className="text-muted">
                      There are three different types of cookies used on
                      ZipLaneRx:
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        <span className="text-primary fw-bold">
                          Persistent cookies
                        </span>{" "}
                        are used to retain user preferences, relating to a
                        particular website you visit. This can be things like
                        whether you want to be logged in automatically when you
                        visit the website, or whether you've already been asked
                        to take part in a survey for example. Persistent cookies
                        are kept for 180 days.
                        {/*exist on your machine for a pre-set length of time.*/}
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        <span className="text-primary fw-bold">
                          Session cookies
                        </span>{" "}
                        are used to retain information during a particular time
                        you've used a website; this can be things like storing
                        the contents of your shopping basket on an e-commerce
                        site. Session cookies are kept for the duration of the
                        user session, they are deleted as soon as you close your
                        browser.
                      </li>
                      <li>
                        <FiArrowRight className="fea icon-sm me-2 mb-0" />
                        <span className="text-primary fw-bold">
                          Third-party cookies
                        </span>{" "}
                        are used by advertisers and social networks to learn
                        about your browsing habits to help deliver relevant
                        advertising and content to you. This, for example means
                        you could be more likely to see adverts for shops you
                        already shop at. Third Party cookies are kept for xxx.
                      </li>
                    </p>
                    <p>What cookies do we use on our site?</p>
                  </ul>

                  <h5 className="card-title">Cookies used on ZipLaneRx:</h5>
                  <ul className="list-unstyled text-muted">
                    <div className="table-responsive shadow rounded mt-4">
                      <table className="table table-center invoice-tb mb-0">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "60px" }}
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              Explanation
                            </th>
                            <th
                              scope="col"
                              className="text-start border-bottom p-3"
                              style={{ minWidth: "200px" }}
                            >
                              Party
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {ziplaneRxCookies.map((cookie) => {
                            return (
                              <tr>
                                <th scope="row" className="text-start p-3">
                                  {cookie.name}
                                </th>
                                <td className="text-start p-3">
                                  {cookie.explanation}
                                </td>
                                <td className="text-start p-3">
                                  {cookie.party}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </ul>

                  {/*<h5 className="card-title">*/}
                  {/*  Cookies used on Support Portal:*/}
                  {/*</h5>*/}
                  {/*<ul className="list-unstyled text-muted">*/}
                  {/*  <div className="table-responsive shadow rounded mt-4">*/}
                  {/*    <table className="table table-center invoice-tb mb-0">*/}
                  {/*      <thead>*/}
                  {/*        <tr>*/}
                  {/*          <th*/}
                  {/*            scope="col"*/}
                  {/*            className="text-start border-bottom p-3"*/}
                  {/*            style={{ minWidth: "60px" }}*/}
                  {/*          >*/}
                  {/*            Name*/}
                  {/*          </th>*/}
                  {/*          <th*/}
                  {/*            scope="col"*/}
                  {/*            className="text-start border-bottom p-3"*/}
                  {/*            style={{ minWidth: "200px" }}*/}
                  {/*          >*/}
                  {/*            Explanation*/}
                  {/*          </th>*/}
                  {/*        </tr>*/}
                  {/*      </thead>*/}
                  {/*      <tbody>*/}
                  {/*        {supportPortalCookies.map((cookie) => {*/}
                  {/*          return (*/}
                  {/*            <tr>*/}
                  {/*              <th scope="row" className="text-start p-3">*/}
                  {/*                {cookie.name}*/}
                  {/*              </th>*/}
                  {/*              <td className="text-start p-3">*/}
                  {/*                {cookie.explanation}*/}
                  {/*              </td>*/}
                  {/*            </tr>*/}
                  {/*          );*/}
                  {/*        })}*/}
                  {/*      </tbody>*/}
                  {/*    </table>*/}
                  {/*  </div>*/}
                  {/*</ul>*/}

                  <h5 className="card-title">Changing your settings</h5>
                  <ul className="list-unstyled text-muted">
                    <p className="text-muted">
                      You can change your browser’s settings, so it’ll tell you
                      when cookies get sent to it. Or it can refuse cookies
                      altogether. Check the Help section in your browser for
                      more info.
                    </p>
                    <p className="text-muted">
                      <span className="fw-bold">Please note:</span> We do not
                      recommend disabling cookies entirely as this will prevent
                      most websites and services (such as webmail and social
                      media sites) from working.
                    </p>
                  </ul>
                  <Link
                    to="#"
                    onClick={() => window.print()}
                    className="btn btn-soft-primary d-print-none"
                  >
                    <MdOutlineLocalPrintshop className="mb-0" /> Print
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
      <ScrollTop />
    </Fragment>
  );
}
